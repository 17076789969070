// TIPI PRESI DA entourance-ws2/ts/services/chat.service.ts

export class WSServiceChatUserProfile {
    id:number = null;
    name:string = null;
    surname:string = null;
    guest:boolean = null;

    constructor(fromData?:any) {
        for(let i in fromData) {
            if(typeof this[i] != 'undefined') {
                this[i] = fromData[i]
            }
        }
    }
}

export class WSServiceChatMessage {
    id?:string;
    from?:string;
    time?:string;
    action:string;
    target?:string;
    content?:string;
    data?:any;
}

export class WSServiceChatUserWatch {
    id:string = null;
    status:string = null;
    profile:WSServiceChatUserProfile = null;
}

export class WSServiceChatUser {
    clients:Array<string> = [];

    constructor(public id:string, public profile:WSServiceChatUserProfile) {}
}

export class WSServiceChatChannel {
    users:Array<string> = [];
    messages:Array<WSServiceChatMessage> = []
    call:{ 
        [user:string]: { 
            client: string, 
            callLog: WSServiceCallLog 
        } 
    } = {}

    constructor(public id:string) {}
}

export class WSServiceCallRoom {
    id:string = null;
    name:string = null;
    url:string = null;

    constructor(fromData?:any) {
        for(let i in fromData) {
            if(typeof this[i] != 'undefined') {
                this[i] = fromData[i]
            }
        }
    }
}

export class WSServiceChatSession {
    id:string = null;
    session_id:string = null;
    username:string = null;
    message_count:number = null;
    data:any = null;
    closed_at:string = null;
    created_at:string = null;
    updated_at:string = null;
    pid:string = null;
    user_id:string = null;
    realm_id:string = null;
}

export class WSServiceCallLog {
    id:string = null;
    channel:string = null;
    username:string = null;
    call_id:string = null;
    in:string = null;
    out:string = null;
    created_at:string = null;
    updated_at:string = null;
}