import { ConfigData, ModelsData, ModelFieldData, DataFilter, DataOrder } from '../services/data.service';
import { ConfigRelationsIndex, ConfigRelation } from "./config.relations.model";
import { PgFileType } from './file.model';

/**
 * ruoli semantici (ie: title, abstract, description...) definiti su una risorsa a partire dai campi della risorsa
 * 
 * vengono usati nelle roleview e nelle select, usano una sintassi di templating
 */

export class ConfigResourceSemantics { 
    slug:string = null;
    
    /**
     * text della select sulla risorsa
     */

    select:string = null;

    /**
     * titolo
     */

    title:string = null;

    /**
     * descrizione breve
     */

    abstract:string = null;

    /**
     * descrizione
     */

    description:string = null;

    /**
     * URL immagine
     */

    image:string = null;

    /**
     * luogo, di tipo descrittivo
     */

    location:string = null;

    /**
     * geolocalizzazione, qualcosa che possa essere passato a google maps per definire il punto geografico esatto (indirizzo o coordinate)
     */

    geoloc:string = null;

    /**
     * data di inizio
     */

    start:string = null;

    /**
     * data di fine
     */

    end:string = null;

    /**
     * prezzo
     */

    price:string = null;
}

/**
 * definisce la configurazione generale di una risorsa
 */

export class ConfigResourceGeneral {
    name:string = null;

    /**
     * la categoria viene usata per organizzare l'albero di navigazione in directory
     */

    category:string = null;

    /**
     * ruoli semantici (ie: title, abstract, description...) definiti su una risorsa a partire dai campi della risorsa
     * 
     * vengono usati nelle roleview e nelle select, usano una sintassi di templating
     */

    roles = new ConfigResourceSemantics();
}

/**
 * definisce i rapporti di master/slave tra i campi
 * 
 * es: location: [ 'nazione', 'regione', 'provincia', 'citta', 'indirizzo', 'cap' ] significa che se in una form è presente un campo di tipo location, imposta automaticamente il valore dei campi elencati con logiche interne al controllo
 */

export let MasterSlaveConfig: { [fieldId:string]: Array<string> } 
= {
    location: [
        'nazione', 
        'regione', 
        'provincia', 
        'citta', 
        'indirizzo', 
        'cap'
    ]
}

/**
 * definisce la compatibilità dei type con i dbtype, cioè quali tipi è possibile impostare in base al tipo base definito nel model
 */

export let CompatibleTypesConfig: { [dbType:string]: Array<string> } 
= {
    float: [ 'integer', 'slider' ],
    decimal: [ 'integer', 'slider' ],
    integer: [ 'boolean', 'select', 'rating', 'slider' ],
    string: [ 'select', 'location', 'password', 'email', 'phone', 'file' ],
    point: [ 'location' ],
    json: [ 'tickets', 'timetable-slots', 'timetable-openings', 'timetable-periods', 'timetable-dates', 'timetable-departures', 'timetable-days' ],
    text: [ 'string', 'html', 'file', 'json', 'tickets', 'timetable-slots', 'timetable-openings', 'timetable-periods', 'timetable-dates', 'timetable-departures', 'timetable-days' ]
}

/**
 * definisce un constraint su un campo della risorsa
 */

export class ConfigResourceTypesConstraint { 
    operator: '==' | '!=' | '<' | '>' | '<=' | '>=';
    field: string;
}

export type ConfigResourceFieldType = 'boolean'|'date'|'time'|'datetime'|'decimal'|'float'|'integer'|'slider'|'string'|'password'|'email'|'phone'|'phone-international'|'recipient'|'text'|'blob'|'html'|'select'|'location'|'list'|'json'|'file'|'rating'|'timetable-slots'|'timetable-openings'|'timetable-periods'|'timetable-dates'|'timetable-departures'|'timetable-days'|'tickets'|'tickets-host'|'survey'|'info'|'split'|'object'

export class ConfigResourceTypesOption {
    value:string|number
    text:string
    hidden?:boolean
    data?:any 
}

export type PgHtmlMode = 'inline-only'|'full';

/**
 * definisce il tipo di un campo di una risorsa
 */

export class ConfigResourceTypes {
    name:string = null;
    label:string = null;
    tooltip?:string = null;
    placeholder?:string = null;

    /**
     * tipo del campo nel model: viene impostato automaticamente dal model e serve per stabilire quale tipo può assumere il campo (es: un campo di tipo string può essere configurato come select)
     */

    dbtype?:'boolean'|'date'|'time'|'datetime'|'decimal'|'float'|'integer'|'string'|'text'|'blob' = null;

    /**
     * tipo del campo
     */

    type:ConfigResourceFieldType = null;

    /**
     * mappa associata al campo: viene impostato automaticamente dal model, forza il tipo select con i valori contenuti nella mappa
     */
    
    map?:{ [value:string]: string } = null;

    /**
     * valido se il tipo è tipo string o text: lunghezza massima
     */

    maxLength?:number = null;

    /**
     * valido se il tipo è tipo string o text: lunghezza dopo la quale viene dato un warning
     */

    warnLength?:number = null;

    /**
     * valido se il tipo è tipo string o text: messaggio warning oltre warnLength
     */

    warnMessage?:string = null;

    /**
     * check custom warning
     */

    warnIf?:string = null;

    /**
     * TODO: check custom error
     */

    //errorIf?:string = null;

    /**
     * valido se il tipo è tipo int, float, date, time o datetime: valore minimo
     */

    min?:any = null;

    /**
     * valido se il tipo è tipo int, float, date, time o datetime: valore massimo
     */

    max?:any = null;

    /**
     * valido se il tipo è tipo float, date, time o datetime: step precisione
     */

    step?:any = null;

    /**
     * valido se il tipo è slider: tipo di scala
     */

    sliderScale?:'normal'|'quadratic' = null;

    /**
     *  valido se il tipo è select o file: flag selezione multipla
     */

    multi?:boolean = null;

    /**
     *  valido se il tipo è select: opzioni selezionabili
     */

    options?:Array<ConfigResourceTypesOption> = null; 

    /**
     *  valido se il tipo è select: filtro per options, nelle values è possibile specificare valori presi dalla form corrente usando {{}}
     */

    optionsFilter?:Array<DataFilter> = null;

    /**
     *  valido se il tipo è select: campo da usare come value
     */

    optionsValue?:string = null;

    /**
     *  valido se il tipo è select: risorsa esterna da cui prendere i valori
     */

    resource?:string = null;

    /**
     * valido se il tipo è select: semantic per costruire il testo delle opzioni
     */

    resourceSemantic?: string = null;

    /**
     * valido se il tipo è select: numero minimo di caratteri per la ricerca
     */

    searchMin?:number = null;

    /**
     * valido se il tipo è file: filtro tipo file
     */

    fileType?:PgFileType = null;

    /**
     * valido se il tipo è file: directory per l'upload
     */

    fileDirectory?:string = null;

    /**
     * valido se il tipo è file: opzioni file
     */

    fileOptions?:{
        acceptString?:string,
        imageRatio?: {
            width:number,
            height:number
        }
    };

    /**
     * valido se il tipo è json: tipo del json
     */

    jsonType?: string;

    /**
     * valido se il tipo è json: tipo del json
     */

    htmlMode?:PgHtmlMode;

    /**
     *  valido se il tipo è timetable: timezone del campo
     */

    timezone?:string = null;

    /**
     * valido se il tipo è info: colore warning
     */

    infoType?:'success'|'warning'|'danger'|'info'

    /**
     * icona associata al campo
     */

    icon?:string;

    /**
     * testo associato al campo
     */

    text?:string;
    
    /**
     *  valore di default
     */

    default?:any = null;

    /**
     *  campo obbligatorio
     */

    required?:boolean = null;

    /**
     *  condizione di obbligatorietà campo
     */

    requiredIf?:string = null;

    /**
     *  campo sola lettura
     */

    readonly?:boolean = null;

     /**
     *  condizione di sola lettura del campo
     */

    readonlyIf?:string = null;

    /**
     *  definisce dei vincoli sul valore del campo rispetto ad altri campi nella form
     */

    constraints?: Array<ConfigResourceTypesConstraint> = null;

    /**
     *  definisce se il campo ha un master, cioè un controllo che imposta il suo valore (es: controllo di tipo location che imposta regione, provincia, città, etc.)
     */

    master?:string = null;

    /**
     *  definisce se il campo ha degli slave, cioè se imposta dei valori in altri campi (es: controllo di tipo location che imposta regione, provincia, città, etc.)
     */

    slaves?:{ [fieldId:string] :string } = {};

    /**
     *  definisce se il campo è multilingua
     */

    locale?:boolean = false;

    // CAMPI IMPOSTATI DA FE in base alla mancanza di configurazione o alla mancanza di campo nel model

    missing?:boolean = false;
    removed?:boolean = false;

    constructor(fromData?:any) {
        if(fromData != null) {
            for(let i in fromData) {
                this[i] = fromData[i];
            }
        }
    }
}

export class PgFormFieldDisplay
{
    oneLine?: boolean; // select: non usa mai la modalità radio/checkbox
    multiLine?: boolean; // select: usa sempre la modalità radio/checkbox
    fullWidth?: boolean; // prende sempre tutta la riga
    inlineLabel?: boolean; // modalità label inline
    info?: string; // tooltip
    invisible?: boolean; // non visibile
}

/**
 * definisce un campo di un gruppo in una form
 */

export class ConfigFormLayoutGroupField {

    /**
     * nome del campo
     */

    name:string = null;

    /**
     * opzioni di visualizzazione
     */

    display?:PgFormFieldDisplay = null;

    /**
     * condizione di visibilità
     */

    condition?:string = null;

    /* campo nascosto */
    hidden?:boolean = null;
}

/**
 * definisce un gruppo di campi o un related in una form
 */

export class ConfigFormLayoutGroup {
    title?:string = null;
    description?:string = null;

    collapsible?:boolean = false;

    /**
     * opzioni di visualizzazione
     */

    display?: any = null;

    /**
     * condizione di visibilità
     */

    condition?:string = null;

    /**
     * se è != null: è un gruppo di campi
     */

    fields?: Array<ConfigFormLayoutGroupField> = null;
}

/**
 * definisce il layout di una form
 */

export class ConfigFormLayout {
    /**
     * le form sono definite come un array di gruppi di campi o risorse in relazione
     */

    groups:Array<ConfigFormLayoutGroup> = null;
}

export class ConfigForm {
    layout: ConfigFormLayout = null;
}

/**
 * definisce una view su una risorsa, cioè il tipo di widget che viene usato per visualizzare gli elementi della risorsa
 * 
 * T è il tipo di configurazione che cambia in base al tipo di view (Table, Calendar, File...)
 */

export class ConfigResourceView<T> {
    constructor(public name:string,
        public type:string,
        public resource:string,
        public config:T) {}
}

/**
 * definisce la configurazione di una risorsa
 */

export class ConfigResource {
    public id:string = null;

    /**
     * configurazioni generali della risorsa
     */

    public general:ConfigResourceGeneral = null;

    /**
     * configurazione dei tipi dei campi della risorsa
     */

    public types: { [name:string] : ConfigResourceTypes } = null;

    /**
     * configurazione della form della risorsa
     */

    public form: ConfigForm = null;

    /**
     * view definite sulla risorsa
     */

    public view: ConfigResourceView<any> = null;

    public version:number = null;

    constructor(public model:string, fromData?:ConfigData, modelFields?:Array<ModelFieldData>, relationsConfig?:Array<ConfigRelation>) {
        if(fromData != null) {
            this.id = fromData.id;

            let _cConfig = JSON.parse(fromData.data);

            this.general = _cConfig.general;
            this.types = _cConfig.types;
            this.view = _cConfig.view;
            this.form = _cConfig.form;
            this.version = _cConfig.version;
        }

        if(this.general == null) {
            this.general = new ConfigResourceGeneral();
        }

        // forzo sempre name traduzioni
        this.general.name = 'RESOURCES.' + this.model + '.name';

        if(this.types == null) {
            this.types = {};
        }
        
        if(this.view == null) {
            this.view = new ConfigResourceView<any>(this.model == 'File' ? 'Files' : 'Table', 
                this.model == 'File' ? 'Files' : 'Table', 
                this.model, null);
        }

        if(this.form == null) {
            this.form = { 
                layout: new ConfigFormLayout()
            }

            this.form.layout.groups = [];
        }

        if(modelFields != null) {
            let _notSystemList = [];

            for(let _field of modelFields) {
                let _isSystemField = true;

                if(_field.name != 'id' && _field.name != 'created_at' && _field.name != 'updated_at' && _field.name != 'deleted_at') {
                    _isSystemField = false;
                    _notSystemList.push(_field.name);
                }

                if(this.types[_field.name] == null) {
                    this.types[_field.name] = new ConfigResourceTypes({
                        name: _field.name,
                        required: _field.name == 'id' ? true : false,
                        type: _field.type,
                        missing: true
                    })
                }

                // forzo sempre label traduzioni
                this.types[_field.name].label = 'RESOURCES.' + this.model + '.fields.' + _field.name + '.label';

                if(!_isSystemField) {
                    let _isFieldInForm = false;

                    for(let _cLayoutGroup of this.form.layout.groups) {
                        if(_cLayoutGroup.fields != null) {
                            for(let _cLayoutField of _cLayoutGroup.fields) {
                                if(_cLayoutField.name == _field.name) {
                                    _isFieldInForm = true;
                                    break;
                                }
                            }
                        }
                    }

                    if(!_isFieldInForm) {
                        let _firstFieldGroup:ConfigFormLayoutGroup = null;

                        for(let _cGroup of this.form.layout.groups) {
                            if(_cGroup.fields != null) {
                                _firstFieldGroup = _cGroup;
                                break;
                            }
                        }

                        if(_firstFieldGroup == null) {
                            _firstFieldGroup = new ConfigFormLayoutGroup();
                            _firstFieldGroup.fields = [];
                            this.form.layout.groups.push(_firstFieldGroup);
                        }

                        _firstFieldGroup.fields.push({
                            name: _field.name, 
                            display: {}, 
                            condition: null 
                        });
                    }
                }

                // CONFIGURAZIONI FORZATE DAL MODEL

                let _types = this.types[_field.name];
                _types.readonly = _field.readonly;
                _types.locale = _field.locale;
                _types.dbtype = _field.type;

                // VERIFICA COMPATIBLE TYPES

                if(_types.dbtype != _types.type) {
                    if(CompatibleTypesConfig[_types.dbtype] == null || CompatibleTypesConfig[_types.dbtype].indexOf(_types.type) == -1) {

                        console.debug('Found incompatible type: ' + this.model + '.' + _field.name + ' was configured as ' + _types.type + ' which is incompatible with dbtype ' + _types.dbtype)

                        _types.type = _types.dbtype;
                    }
                }

                // MAPS
                
                if(_field.map != null) {
                    _types.map = _field.map.values;
                    if(_field.map.multiple != null) _types.multi = _field.map.multiple;
                    _types.type = 'select';
                    _types.options = [];

                    for(let i in _field.map.values) {
                        _types.options.push({ value: i, text: _field.map.values[i] })
                    }
                }

                // LOCATION

                if(_field.name == 'location') {
                    _types.type = 'location';
                }
            }

            for(let i in this.types) {
                let _removed = true;

                for(let _field of modelFields) {
                    if(_field.name == i) {
                        _removed = false;
                        break;
                    }
                }

                if(_removed) {
                    this.types[i].removed = true;
                }
            }

            // MASTER/SLAVE

            for(let _cMaster of modelFields) {
                this.types[_cMaster.name].master = null;
                this.types[_cMaster.name].slaves = {};
            }

            for(let _cMaster of modelFields) {
                // ho usato questa cosa dell'underscore in modo che si possano raggruppare i master/slave
                // per esempio: location_nascita e location_residenza sono master di 2 set di slave separati

                let _masterType = _cMaster.name.split('_')[0];
                let _masterSub = _cMaster.name.split('_')[1];
                
                let _cSlaveList = MasterSlaveConfig[_masterType];
                if(_cSlaveList != null) {
                    
                    for(let _cSlave of modelFields) {

                        let _cSlaveType = _cSlave.name.split('_')[0];
                        let _cSlaveSub = _cSlave.name.split('_')[1];

                        if(_masterSub == _cSlaveSub && _cSlaveList.indexOf(_cSlaveType) > -1) {
                            this.types[_cSlave.name].master = _cMaster.name;
                            this.types[_cMaster.name].slaves[_cSlave.name] = _cSlaveType;
                        }
                    }
                }
            }

            // DEFAULT ROLES

            // priorità ad alcuni campi con nomi specifici

            for(let _field of ['title','name']) { // ordine invertito
                let _index = _notSystemList.indexOf(_field);
                if(_index != -1) {
                    _notSystemList.splice(_index, 1)
                    _notSystemList.unshift(_field)
                }
            }

            // default per select e title: {{id}} - {{notId[0]}}

            if(this.general.roles.select == null) {
                this.general.roles.select = '{{id}} - {{' + _notSystemList[0] + '}}';
            }

            if(this.general.roles.title == null) {
                this.general.roles.title = '{{id}} - {{' + _notSystemList[0] + '}}';
            }

            // default per abstract:
            // se ho un solo campo notId: vuoto
            // se ho 2 o 3 campi notId: {{notId[1]}}
            // se ho 4+ campi notId: {{notId[1]}} {{notId[2]}}

            if(this.general.roles.abstract == null) {
                
                this.general.roles.abstract = '';
                
                if(_notSystemList.length > 1) {
                    this.general.roles.abstract = '{{' + _notSystemList[1] + '}}';

                    if(_notSystemList.length > 3) {
                        this.general.roles.abstract += ' {{' + _notSystemList[2] + '}}';
                    }
                }
            }

            // default per description:
            // se ho meno di 3 campi notId: vuoto 
            // se ho 3 campi notId: {{notId[2]}}
            // se ho più di 3 campi notId: {{notId[3]}} {{notId[4]}} {{notId[5]}}

            if(this.general.roles.description == null) {
                this.general.roles.description = '';

                if(_notSystemList.length > 2) {
                    if(_notSystemList.length == 3) {
                        this.general.roles.description = '{{' + _notSystemList[2] + '}}';
                    }
                    else {
                        for(let i = 3; i < _notSystemList.length && i < 6; i++) {
                            if(this.general.roles.description != '') this.general.roles.description += ' ';
                            this.general.roles.description += '{{' + _notSystemList[i] + '}}';
                        }
                    }
                }
            }

            // default per image:
            // se c'è il campo immagine, url della risorsa file in relazione

            if(this.general.roles.image == null) {
                this.general.roles.image = '';

                for(let _field of modelFields) {
                    if(_field.name == 'immagine') {
                        this.general.roles.image = '{{related.onfield.immagine.url}}';
                        break;
                    }
                }
            }

            // default per geoloc:
            // provo a comporlo usando eventuali campi indirizzo, citta e primo notId

            if(this.general.roles.geoloc == null) {
                this.general.roles.geoloc = '';

                let _requiredFields = {
                    citta: false,
                    indirizzo: false
                }

                for(let _field of modelFields) {
                    if(_requiredFields[_field.name] != null) {
                        _requiredFields[_field.name] = true
                    }
                }

                let _areAllFieldsIn = true;

                for(let i in _requiredFields) {
                    _areAllFieldsIn = _areAllFieldsIn && _requiredFields[i];
                }

                if(_areAllFieldsIn) {
                    this.general.roles.geoloc = '{{' + _notSystemList[0] + '}}, {{indirizzo}}, {{citta}}';
                }
            }

            // default per location:
            // se esiste citta è primo notId - citta

            if(this.general.roles.location == null) {
                this.general.roles.location = '';

                for(let _field of modelFields) {
                    if(_field.name == 'citta') {
                        this.general.roles.location = '{{' + _notSystemList[0] + '}} - {{citta}}';
                    }
                }
            }

            // CUSTOM INIT SCRIPTS

            // TODO: trovargli un posto
            // TODO: obsoleto, dovrebbe gestire anche i gruppi

            /*
            if(this.form.conditions.biglietto_intero != null) {
                for(let _cType of ['', '_cumulativo', '_famiglia']) {
                    for(let i = 0; i < 5; i++) {
                        let _field = 'biglietto' + _cType + '_intero';
                        if(i > 0) _field = 'biglietto' + _cType + '_ridotto_' + i;

                        this.form.conditions['biglietto' + _cType + '_ridotto_' + (i + 1)] = {
                            field: _field,
                            operator: '!=',
                            value: ''
                        }

                        this.form.conditions['biglietto' + _cType + '_ridotto_' + (i + 1) + '_label'] = {
                            field: _field,
                            operator: '!=',
                            value: ''
                        }
                    }
                }
            }
            */
        }
    }

    /**
     * ritorna il JSON di configurazione
     */

    toData(save:boolean):ConfigData {
        let _retData = new ConfigData();

        _retData.id = this.id;
        _retData.modello = this.model;
        _retData.tipo = 'resource';

        let _types:any = {}

        if(!save) {
            _types = this.types;
        }
        else {
            for(let i in this.types) {
                if(!this.types[i].removed) {
                    _types[i] = {};
                
                    for(let j in this.types[i]) {
                        if(j != 'missing' && j != 'map' && (this.types[i].map == null || j != 'options')) {
                            _types[i][j] = this.types[i][j]
                        }  
                    }   
                }
            }
        }

        _retData.data = JSON.stringify({
            general: this.general,
            types: _types,
            form: this.form,
            view: this.view,
            version: this.version
        });

        return _retData;
    }
}

/**
 * indice delle configurazioni delle le risorse
 */

export class ConfigResourcesIndex {
    public list: Array<ConfigResource> = [];
    public byModel: { [model:string] : ConfigResource } = {};

    constructor(fromData?:Array<ConfigData>, modelsData?:ModelsData, relationsIndex?:ConfigRelationsIndex) {
        if(fromData != null) {
            for(let _cRow of fromData) {
                if(_cRow.tipo == 'resource') {
                    let _cRelations = [];
                    if(relationsIndex != null) _cRelations = relationsIndex.byModel[_cRow.modello];

                    this._addResource(new ConfigResource(_cRow.modello, _cRow, modelsData[_cRow.modello], _cRelations))
                }
            }
        }

        if(modelsData != null) {
            for(let i in modelsData) {   
                if(this.byModel[i] == null) { // se la configurazione non è definita
                    this._addResource(new ConfigResource(i, null, modelsData[i]))
                }
            }
        }
    }

    _addResource(config:ConfigResource) {
        this.byModel[config.model] = config
        this.list.push(config);
    }
}