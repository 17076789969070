import { Component } from '@angular/core';
import { FormFlow, PgFormField, PgFormGroup, PgFormLayout } from '../../../models/form.model';
import { StepFormBase } from '../step-form-base';

@Component({
  selector: 'app-forms-poi',
  templateUrl: './forms-poi.component.html',
  styleUrls: ['./forms-poi.component.scss']
})
export class FormsPoiComponent extends StepFormBase {

    formId = 'poi';
    resourceId = 'Poi';

    createFormFlow() {
        return new FormFlow({
            'dati_generali': {
                title: null,
                form: new PgFormLayout([
                    new PgFormField({ label: 'auto', type: 'string', name: 'name@PoiTranslation', required: true, locale: true, tooltip: 'auto' }),
                    new PgFormField({ label: 'auto', type: 'phone', name: 'landline_number' }),
                    new PgFormField({ label: 'auto', type: 'phone', name: 'phone' }),
                    new PgFormField({ label: 'auto', type: 'email', name: 'email' }),
                    new PgFormField({ label: 'auto', type: 'string', name: 'website' }),
                    new PgFormField({ label: 'auto', type: 'string', name: 'tags', tooltip: 'auto' }),
                    new PgFormField({ label: 'auto', type: 'select', multi: true, name: 'languages', required: true }),
                    new PgFormField({ label: 'auto', type: 'location', name: 'geolocation', placeholder: 'auto', 
                        slaves: {
                            'country': 'country',
                            'region': 'region',
                            'province': 'province',
                            'city': 'city',
                            'address': 'address',
                            'zipcode': 'zipcode',
                        }
                    }),
                    new PgFormField({ label: 'auto', type: 'info', name: 'geolocation_missing', tooltip: 'auto', infoType: 'warning' }),
                    new PgFormField({ label: 'auto', type: 'string', name: 'location_description@PoiTranslation', locale: true }),
                    new PgFormField({ label: 'auto', type: 'string', name: 'country', readonly: true }),
                    new PgFormField({ label: 'auto', type: 'string', name: 'region', readonly: true }),
                    new PgFormField({ label: 'auto', type: 'string', name: 'province', readonly: true }),
                    new PgFormField({ label: 'auto', type: 'string', name: 'city', readonly: true }),
                    new PgFormField({ label: 'auto', type: 'string', name: 'address', readonly: true }),
                    new PgFormField({ label: 'auto', type: 'string', name: 'zipcode', readonly: true }),
                    new PgFormField({ label: 'auto', type: 'select', name: 'group_id' }),
                    new PgFormField({ label: 'auto', type: 'select', name: 'realm_id' }),
                    new PgFormField({ label: 'auto', type: 'select', name: 'priority', default: 5, required: true }),
                ])
            },
            'descrizione': {
                title: null,
                form: new PgFormLayout([
                    new PgFormField({ label: 'auto', type: 'select', name: 'category', required: true }),
                    new PgFormField({ label: 'auto', type: 'select', name: 'subcategory',
                        optionsFilter: [
                            { field: 'category', operator: '==', value: [ '{{$form.category}}' ]}
                        ]
                    }),
                    new PgFormField({ label: 'auto', type: 'html', name: 'description@PoiTranslation', required: true, locale: true, tooltip: 'auto', maxLength: 5000 }),
                    new PgFormField({ label: 'auto', type: 'html', name: 'information@PoiTranslation', required: false, locale: true, maxLength: 5000 }),
                    new PgFormField({ label: 'auto', type: 'html', name: 'contact@PoiTranslation', required: false, locale: true, maxLength: 5000 }),
                    new PgFormField({ label: 'auto', type: 'boolean', name: 'stroller_friendly' }),
                    new PgFormField({ label: 'auto', type: 'select', name: 'physical_accessibility' }),
                    new PgFormField({ label: 'auto', type: 'select', name: 'cognitive_accessibility' }),
                    new PgFormField({ label: 'auto', type: 'select', name: 'blindness_accessibility' }),
                    new PgFormField({ label: 'auto', type: 'select', name: 'deafness_accessibility' }),
                    new PgFormField({ label: 'auto', type: 'text', name: 'accessibility_description@PoiTranslation', locale: true }),
                ])
            },
            'disponibilita': {
                title: null,
                form: new PgFormLayout([
                    new PgFormField({ label: 'auto', type: 'string', name: 'booking', tooltip: 'auto' }),
                    new PgFormField({ label: 'auto', type: 'timetable-openings', name: 'availability', multi: true }),
                ])
            },
            'file': {
                title: null,
                form: new PgFormLayout([
                    new PgFormField({ label: 'auto', type: 'file', multi: false, fileType: 'images', name: 'cover' }),
                    new PgFormField({ label: 'auto', type: 'file', multi: true, fileType: 'images', name: 'images' }),
                    new PgFormField({ label: 'auto', type: 'file', multi: true, fileType: 'videos', name: 'videos' }),
                ])
            }
        })
    }

    customizeFormFlow() {
        for(let _cField of ['country','region','province','city','address','zipcode']) {
            this.formFlow.states['dati_generali'].form.getFieldByName(_cField).visible = false;
        }
        
        this.formFlow.states['dati_generali'].form.getFieldLayout('geolocation_missing').condition = '!$form.poi_id && !$form.geolocation';

        this.formFlow.states['dati_generali'].form.getFieldLayout('tags').display = { fullWidth: true }

        this.formFlow.states['dati_generali'].form.getFieldByName('priority').visible = false;

        this.formFlow.states['descrizione'].form.getFieldLayout('subcategory').condition = '$self.options.some((element) => { return element.data.category == $form.category })';

        let _accessibilityFields:Array<PgFormField> = [];
        let _groupFields = this.formFlow.states['descrizione'].form.formGroups[0].fields

        for(let _name of ['physical_accessibility','cognitive_accessibility','blindness_accessibility','deafness_accessibility','accessibility_description@PoiTranslation']) {
            let _field = this.formFlow.states['descrizione'].form.getFieldByName(_name)

            _groupFields.splice(_groupFields.indexOf(_field), 1)
            _accessibilityFields.push(_field)
        }

        this.formFlow.states['descrizione'].form.formGroups.push(new PgFormGroup({
            title: 'RESOURCES.' + this.resourceId + '.fields.accessibility.label',
            description: 'RESOURCES.' + this.resourceId + '.fields.accessibility.tooltip',
            collapsible: false,
            fields: _accessibilityFields
        }))
    }

    mapImportData(data:google.maps.places.PlaceResult) {
        let _addressComponents = this.parseAddressComponents(data.address_components)

        return {
            country: _addressComponents.country,
            region: _addressComponents.region,
            province: _addressComponents.province,
            city: _addressComponents.city,
            address: _addressComponents.address,
            zipcode: _addressComponents.zipcode,
            website: data.website,
            landline_number: data.international_phone_number,
            name: data.name,
            geolocation: { 
                coordinates: [data.geometry.location.lat(), data.geometry.location.lng()], 
                type: 'Point' 
            }
        }
    }

    initData(): Promise<null> {
        return new Promise<null>((resolve, reject) => {
            resolve(null);
        })
    }

    adaptLoadData(values: any) {
        return values
    }

    adaptSaveData(values: any) {
        return values
    }
}
