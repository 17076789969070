import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { FormFlow, FormFlowState, PgFormField, PgFormGroup, PgFormLayout } from '../../../models/form.model';
import { ConfigFormLayoutGroupField } from '../../../models/config.resources.model';
import { StepFormBase } from '../step-form-base';
import { DataService } from '../../../services/data.service';
import { EnvironmentService } from '../../../services/environment.service';
import { LocalizationService } from '../../../services/localization.service';
import { NotificationsService } from '../../../services/notifications.service';
import { OptionMapsService } from '../../../services/option-maps.service';
import { PGUtilities } from '../../../pg-utilities';
import { AuthService } from '../../../services/auth.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-forms-experience',
  templateUrl: './forms-experience.component.html',
  styleUrls: ['./forms-experience.component.scss']
})
export class FormsExperienceComponent extends StepFormBase {

    constructor(protected optionMapsService:OptionMapsService, protected localizationService:LocalizationService, protected dataService:DataService, protected authService:AuthService, protected router:Router, protected route:ActivatedRoute, protected environmentService:EnvironmentService, protected notificationsService:NotificationsService, private modalService:NgbModal) {
        super(optionMapsService, localizationService, dataService, authService, router, route, environmentService, notificationsService);
    }

    formId = 'experience';
    resourceId = 'Experience';

    getDraftResourceId() {
        return this.resourceId + (this.isTemplate ? '~Template' : '')
    }
    
    @Input() certificateOptions:{
        title: string,
        description: string,
        checks: Array<string>
    }

    @Input() typeFilter:string;
    @Input() isTemplate:boolean;

    // TODO: per qualche motivo, carico qua staticamente tutti i contenuti delle select invece di usare il campo resource
    private _supplierOptions:Array<{ value:string, text: string, order: number }> = null;
    private _POIOptions:Array<{ value:string, text: string, order: number, geolocation: any }> = null;
    private _tourProductOptions:Array<{ value:string, text: string, order: number }> = null;
    private _mainEventOptions:Array<{ value:string, text: string, hidden?:boolean }> = null;

    private _paymentMethodOptions:Array<{ value:string, text: string }> = [{
        value: 'contanti',
        text: 'forms-experience.options.payment-method.contanti'
    }, {
        value: 'bancomat',
        text: 'forms-experience.options.payment-method.bancomat'
    }, {
        value: 'carta_di_credito',
        text: 'forms-experience.options.payment-method.carta-di-credito'
    }]

    private _bookingDeadlineOptions:Array<{ value:string, text: string }> = [{
        value: '1',
        text: 'forms-experience.options.booking-deadline.1h'
    }, {
        value: '4',
        text: 'forms-experience.options.booking-deadline.4h'
    }, {
        value: '12',
        text: 'forms-experience.options.booking-deadline.12h'
    }, {
        value: '24',
        text: 'forms-experience.options.booking-deadline.1d'
    }, {
        value: '48',
        text: 'forms-experience.options.booking-deadline.2d'
    }, {
        value: '96',
        text: 'forms-experience.options.booking-deadline.4d'
    }, {
        value: '168',
        text: 'forms-experience.options.booking-deadline.1w'
    }]

    private _bookingNotificationOptions:Array<{ value:string, text: string }> = [{
        value: 'user',
        text: 'forms-experience.options.booking-notification.user'
    }, {
        value: 'supplier',
        text: 'forms-experience.options.booking-notification.supplier'
    }, {
        value: 'both',
        text: 'forms-experience.options.booking-notification.both'
    }]

    _typeOptions:Array<{ value: string, text: string }> = null;
    _typeDefault:string = null;

    ngOnChanges() {
        this._typeOptions = this.optionMapsService.getResourceFieldOptionMap('Experience', 'type')
        this._typeDefault = 'activity'

        if(this.typeFilter == 'event') {
            this._typeDefault = 'event'
        }

        super.ngOnChanges()
    }

    private _loadSupplierOptions() {
        return new Promise<null>((resolve, reject) => {
            this._supplierOptions.splice(0)

            let _supplierResources = ['Host','Eatery','ExperienceSupplier'];
            let _reqNum = _supplierResources.length;
    
            for(let i = 0; i < _supplierResources.length; i++) {
                let _cResource = _supplierResources[i];
    
                this.dataService.getResourceData(_cResource, { limit: 1000 }).subscribe((data) => {
                    for(let _cData of data) {
                        this._supplierOptions.push({ value: _cResource + '_' + _cData.id, text: _cData.name, order: i })
                    }
    
                    this._supplierOptions.sort((a, b) => {
                        if(a.order != b.order) {
                            if(a.order < b.order) return -1;
                            else if(a.order > b.order) return 1;
                        }
                        else {
                            if(a.text < b.text) return -1;
                            else if(a.text > b.text) return 1;
                            else return 0;
                        }
                    })
    
                    _reqNum--;
                    if(_reqNum <= 0) {
                        resolve(null)
                    }
                }, () => {
                    _reqNum--;
                    if(_reqNum <= 0) {
                        resolve(null)
                    }
                })
            }
        })
    }

    initData() {
        return new Promise<null>((resolve, reject) => {
            for(let _cOption of this._paymentMethodOptions) {
                _cOption.text = this.localizationService.translate(_cOption.text)
            }

            for(let _cOption of this._bookingDeadlineOptions) {
                _cOption.text = this.localizationService.translate(_cOption.text)
            }

            for(let _cOption of this._bookingNotificationOptions) {
                _cOption.text = this.localizationService.translate(_cOption.text)
            }

            let _reqNum = 1;

            this._supplierOptions = [];

            this._loadSupplierOptions().then(() => {
                _reqNum--;
                if(_reqNum <= 0) {
                    resolve(null)
                }
            })

            this._POIOptions = [];

            _reqNum++;
            this.dataService.getResourceData('Poi', { limit: 1000 }).subscribe((data) => {
                for(let _cData of data) {
                    this._POIOptions.push({ value: _cData.id, text: _cData.name, order: _cData.id, geolocation: _cData.geolocation })
                }

                this._POIOptions.sort((a, b) => {
                    if(a.order != b.order) {
                        if(a.order < b.order) return -1;
                        else if(a.order > b.order) return 1;
                    }
                    else {
                        if(a.text < b.text) return -1;
                        else if(a.text > b.text) return 1;
                        else return 0;
                    }
                })

                _reqNum--;
                if(_reqNum <= 0) {
                    resolve(null)
                }
            }, () => {
                _reqNum--;
                if(_reqNum <= 0) {
                    resolve(null)
                }
            })

            this._tourProductOptions = [];

            _reqNum++;
            this.dataService.getResourceData('TourProduct', { limit: 1000 }).subscribe((data) => {
                for(let _cData of data) {
                    this._tourProductOptions.push({ value: _cData.id, text: _cData.name, order: _cData.id })
                }

                this._tourProductOptions.sort((a, b) => {
                    if(a.order != b.order) {
                        if(a.order < b.order) return -1;
                        else if(a.order > b.order) return 1;
                    }
                    else {
                        if(a.text < b.text) return -1;
                        else if(a.text > b.text) return 1;
                        else return 0;
                    }
                })

                _reqNum--;
                if(_reqNum <= 0) {
                    resolve(null)
                }
            }, () => {
                _reqNum--;
                if(_reqNum <= 0) {
                    resolve(null)
                }
            })

            this._mainEventOptions = [];

            _reqNum++;
            this.dataService.getResourceData('Experience~Event', { filter: [
                { field: 'template', operator: '==', value: [0] },
            ], limit: 1000 }).subscribe((data) => {
                for(let _cData of data) {
                    this._mainEventOptions.push({ value: _cData.id, text: _cData.id + ' - ' + _cData.title })
                }

                this._mainEventOptions.sort((a, b) => {
                    if(a.text < b.text) return -1;
                    else if(a.text > b.text) return 1;
                    else return 0;
                })

                _reqNum--;
                if(_reqNum <= 0) {
                    resolve(null)
                }
            }, () => {
                _reqNum--;
                if(_reqNum <= 0) {
                    resolve(null)
                }
            })
        })
    }

    createFormFlow() {
        let _cPaymentTooltip = '';
        let _cPaymentOptions = this.optionMapsService.getResourceFieldOptionMap('Experience', 'payment')
        for(let _cOption of _cPaymentOptions) {
            if(_cPaymentTooltip != '') _cPaymentTooltip += ','
            _cPaymentTooltip += '"RESOURCES.' + this.resourceId + '.fields.payment-' + _cOption.value + '.tooltip"';
        }

        _cPaymentTooltip = '(service) => { let _retVal = ""; for(let _cTransl of [' + _cPaymentTooltip + ']) { _retVal += service.translate(_cTransl) + "<br/>" }; return _retVal; }'

        let _cStates:{ [name:string]: FormFlowState } = {
            'dati_generali': {
                title: null,
                form: new PgFormLayout([
                    new PgFormField({ label: 'auto', type: 'boolean', name: 'template', readonly: true }),
                    new PgFormField({ label: 'auto', type: 'string', name: 'title@ExperienceTranslation', required: true, locale: true, maxLength: 100 }),
                    new PgFormField({ label: 'auto', type: 'select', name: 'category', required: true }),
                    new PgFormField({ label: 'auto', type: 'select', name: 'subcategory',
                        optionsFilter: [
                            { field: 'category', operator: '==', value: [ '{{$form.category}}' ]}
                        ]
                    }),
                    new PgFormField({ label: 'auto', type: 'string', name: 'tags', tooltip: 'auto' }),
                    new PgFormField({ label: 'auto', type: 'select', name: 'supplier', options: this._supplierOptions, tooltip: 'auto', requiredIf: '!$form.template' }, null, [{ 
                        icon: 'fa-regular fa-plus', 
                        label: 'RESOURCES.' + this.resourceId + '.fields.supplier.action_create',
                        run: () => { 
                            this._createSupplier()
                         }
                    }]),
                    new PgFormField({ label: 'auto', type: 'select', name: 'type', options: this._typeOptions, default: this._typeDefault, readonly: this.typeFilter == 'event', required: true }),
                    new PgFormField({ label: 'auto', type: 'select', multi: true, name: 'languages', tooltip: 'auto', required: true }),
                    new PgFormField({ label: 'auto', type: 'boolean', name: 'online', tooltip: 'auto' }),
                    new PgFormField({ label: 'auto', type: 'select', name: 'poi_id', options: this._POIOptions }),
                    new PgFormField({ label: 'auto', type: 'location', name: 'geolocation', readonlyIf: '$form.poi_id', 
                        slaves: {
                            'country': 'country',
                            'region': 'region',
                            'province': 'province',
                            'city': 'city',
                            'address': 'address',
                            'zipcode': 'zipcode',
                            'timezone': 'timezone'
                        } 
                    }),
                    new PgFormField({ label: 'auto', type: 'info', name: 'geolocation_missing', tooltip: 'auto', infoType: 'warning' }),
                    new PgFormField({ label: 'auto', type: 'string', name: 'location_description@ExperienceTranslation', locale: true }),
                    new PgFormField({ label: 'auto', type: 'string', name: 'country', readonly: true }),
                    new PgFormField({ label: 'auto', type: 'string', name: 'region', readonly: true }),
                    new PgFormField({ label: 'auto', type: 'string', name: 'province', readonly: true }),
                    new PgFormField({ label: 'auto', type: 'string', name: 'city', readonly: true }),
                    new PgFormField({ label: 'auto', type: 'string', name: 'address', readonly: true }),
                    new PgFormField({ label: 'auto', type: 'string', name: 'zipcode', readonly: true }),
                    new PgFormField({ label: 'auto', type: 'string', name: 'timezone', readonly: true, default: 'Europe/Rome' }),
                    new PgFormField({ label: 'auto', type: 'select', name: 'group_id' }),
                    new PgFormField({ label: 'auto', type: 'select', name: 'realm_id' }),
                    new PgFormField({ label: 'auto', type: 'select', name: 'priority', default: 5, required: true }),
                ])
            },
            'descrizione': {
                title: null,
                form: new PgFormLayout([
                    new PgFormField({ label: 'auto', type: 'html', name: 'description@ExperienceTranslation', required: true, locale: true, tooltip: 'auto', maxLength: 1500 }),
                    new PgFormField({ label: 'auto', type: 'html', name: 'whats_included@ExperienceTranslation', locale: true, tooltip: 'auto', maxLength: 1000 }),
                    //new PgFormField({ label: 'auto', type: 'html', name: 'useful_information@ExperienceTranslation', locale: true, maxLength: 500 }),
                    //new PgFormField({ label: 'auto', type: 'rating', name: 'cost_range', icon: 'fas fa-euro-sign' }),
                    new PgFormField({ label: 'auto', type: 'select', name: 'tour_product_id', options: this._tourProductOptions }),
                    new PgFormField({ label: 'auto', type: 'select', name: 'parent', tooltip: 'auto', options: this._mainEventOptions }),
                    new PgFormField({ label: 'auto', type: 'select', multi: true, name: 'target', required: true, tooltip: 'auto' }),
                    new PgFormField({ label: 'auto', type: 'boolean', name: 'stroller_friendly' }),
                    new PgFormField({ label: 'auto', type: 'select', name: 'physical_accessibility' }),
                    new PgFormField({ label: 'auto', type: 'select', name: 'cognitive_accessibility' }),
                    new PgFormField({ label: 'auto', type: 'select', name: 'blindness_accessibility' }),
                    new PgFormField({ label: 'auto', type: 'select', name: 'deafness_accessibility' }),
                    new PgFormField({ label: 'auto', type: 'text', name: 'accessibility_description@ExperienceTranslation', locale: true }),
                ])
            },
            'disponibilita': {
                title: null,
                form: new PgFormLayout([
                    new PgFormField({ label: 'auto', type: 'boolean', name: 'bookable', default: false }),
                    new PgFormField({ label: 'auto', type: 'string', name: 'booking', tooltip: 'auto' }),
                    new PgFormField({ label: 'auto', type: 'select', name: 'booking_notification', default: 'user', options: this._bookingNotificationOptions }),
                    new PgFormField({ label: 'auto', type: 'boolean', name: 'mandatory_email', default: false }),
                    new PgFormField({ label: 'auto', type: 'boolean', name: 'price_on_request', tooltip: 'auto', default: false }),
                    // a partire dal 5 campo vengono messi alla fine, vedi sotto
                    // NB: parametrizzato per installazione min max partecipanti e cancellation_deadline tramite CustomFormConfig
                    new PgFormField({ label: 'auto', type: 'boolean', name: 'open_event', tooltip: 'auto' }),
                    new PgFormField({ label: 'auto', type: 'slider', min: 1, max: 5, name: 'minimum_guaranteed', default: 1, tooltip: 'auto', requiredIf: '$form.bookable' }),
                    new PgFormField({ label: 'auto', type: 'boolean', name: 'overbooking', default: false, tooltip: 'auto' }),
                    new PgFormField({ label: 'auto', type: 'slider', min: 3, max: 15, name: 'maximum_participants', default: 10, tooltip: 'auto', requiredIf: '$form.bookable' }),
                    new PgFormField({ label: 'auto', type: 'select', name: 'booking_deadline', options: this._bookingDeadlineOptions, default: 12, tooltip: 'auto', requiredIf: '$form.bookable' }),
                    new PgFormField({ label: 'auto', type: 'slider', min: 1, max: 30, name: 'cancellation_deadline', default: 7, tooltip: 'auto', requiredIf: '$form.bookable' }),
                    new PgFormField({ label: 'auto', type: 'boolean', name: 'instant_confirmation', default: false, tooltip: 'auto' }),
                    new PgFormField({ label: 'auto', type: 'integer', min: 0, max: 100, name: 'broker_discount', default: 20, icon: 'fas fa-percent', tooltip: 'auto', requiredIf: '$form.bookable' }),
                    new PgFormField({ label: 'auto', type: 'timetable-dates', name: 'availability_event', multi: true }),
                    new PgFormField({ label: 'auto', type: 'timetable-days', name: 'availability_package', multi: true }),
                    new PgFormField({ label: 'auto', type: 'timetable-slots', name: 'availability_activity', multi: true }),
                    new PgFormField({ label: 'auto', type: 'tickets', name: 'tickets', locale: true }), 
                    // questi ultimi 4 campi sono invertiti per via della manipolazione che viene fatta nella customizeFormFlow
                ])
            },
            'pagamento': {
                title: null,
                form: new PgFormLayout([
                    new PgFormField({ label: 'auto', type: 'boolean', name: 'bookable' }),
                    new PgFormField({ label: 'auto', type: 'boolean', name: 'price_on_request', default: false }),
                    new PgFormField({ label: 'auto', type: 'tickets', name: 'tickets', locale: true }), 
                    // NB: questi primi due campi mi servono per valutare le condizioni di quelli sotto, verranno messi non visibili
                    // sarebbe da fixare la evaluateCondition in modo che funzioni anche se ci sono i valori ma non i campi nella form
                    new PgFormField({ label: 'auto', type: 'info', name: 'payment_not_applicable', tooltip: 'auto' }), 
                    new PgFormField({ label: 'auto', type: 'select', multi: true, name: 'payment', tooltip: _cPaymentTooltip, requiredIf: '$form.bookable && !$form.price_on_request && $form.tickets != null && tryParseJSON($form.tickets).fullPrice > 0' }),
                    new PgFormField({ label: 'auto', type: 'string', name: 'iban', requiredIf: '$form.bookable && !$form.price_on_request && $form.tickets != null && tryParseJSON($form.tickets).fullPrice > 0 && tryParseJSON($form.payment).indexOf(\'bonifico\') != -1' }),
                    new PgFormField({ label: 'auto', type: 'string', name: 'swift', requiredIf: '$form.bookable && !$form.price_on_request && $form.tickets != null && tryParseJSON($form.tickets).fullPrice > 0 && tryParseJSON($form.payment).indexOf(\'bonifico\') != -1' }),
                    new PgFormField({ label: 'auto', type: 'select', multi: true, name: 'payment_method', options: this._paymentMethodOptions, requiredIf: '$form.bookable && !$form.price_on_request && $form.tickets != null && tryParseJSON($form.tickets).fullPrice > 0 && tryParseJSON($form.payment).indexOf(\'sul_posto\') != -1' }),
                ])
            },
            'file': {
                title: null,
                description: 'auto',
                form: new PgFormLayout([
                    new PgFormField({ label: 'auto', type: 'file', multi: true, fileType: 'videos', name: 'videos' }),
                    new PgFormField({ label: 'auto', type: 'file', multi: true, fileType: 'images', name: 'images', required: true, 
                        fileOptions: { 
                            imageRatio: { width: 4, height: 3 } 
                        } 
                    }),
                ])
            },
            'survey': {
                title: null,
                description: 'auto',
                form: new PgFormLayout([
                    new PgFormField({ label: '', type: 'survey', name: 'survey', locale: true }),
                ])
            }
        }

        if(this.certificateOptions != null) {
            let _checksFields = [];

            for(let i = 0; i < this.certificateOptions.checks.length; i++) {
                _checksFields.push(new PgFormField({ label: this.certificateOptions.checks[i], type: 'boolean', name: 'certificate_' + i, required: true }))
            }

            let _checksLayout = new PgFormLayout(_checksFields);

            for(let _cField of _checksLayout.formGroups[0].fields) {
                _cField.display = { fullWidth: true, inlineLabel: true }
            }

            _cStates['certificate'] = {
                title: this.certificateOptions.title,
                description: this.certificateOptions.description,
                form: _checksLayout
            }
        }

        return new FormFlow(_cStates);
    }

    customizeFormFlow() {
        for(let _cField of ['country','region','province','city','address','zipcode','timezone']) {
            this.formFlow.states['dati_generali'].form.getFieldByName(_cField).visible = false;
        }

        this.formFlow.states['dati_generali'].form.getFieldLayout('poi_id').condition = '!$form.online';
        this.formFlow.states['dati_generali'].form.getFieldLayout('geolocation').condition = '!$form.online';
        this.formFlow.states['dati_generali'].form.getFieldLayout('location_description@ExperienceTranslation').condition = '!$form.online';
        this.formFlow.states['dati_generali'].form.getFieldLayout('geolocation_missing').condition = '!$form.online && !$form.poi_id && !$form.geolocation';

        this.formFlow.states['dati_generali'].form.getFieldLayout('tags').display = { fullWidth: true }

        this.formFlow.states['dati_generali'].form.getFieldByName('priority').visible = false;

        this.formFlow.states['dati_generali'].form.getFieldLayout('template').condition = '$form.template';
        this.formFlow.states['dati_generali'].form.getFieldLayout('supplier').condition = '!$form.template';
        this.formFlow.states['dati_generali'].form.getFieldLayout('supplier').display = { oneLine: true }
        this.formFlow.states['dati_generali'].form.getFieldLayout('poi_id').display = { oneLine: true }
        this.formFlow.states['descrizione'].form.getFieldLayout('parent').display = { oneLine: true }
        this.formFlow.states['descrizione'].form.getFieldLayout('tour_product_id').display = { oneLine: true }
        this.formFlow.states['disponibilita'].form.getFieldLayout('booking_deadline').display = { oneLine: true }

        this.formFlow.states['disponibilita'].form.getFieldLayout('bookable').condition = '!$form.template';

        // questa è la manipolazione a cui faccio riferimento sopra

        let _cTicketsField = this.formFlow.states['disponibilita'].form.formGroups[0].fields.pop();
        this.formFlow.states['disponibilita'].form.formGroups.push(new PgFormGroup({
            title: 'RESOURCES.' + this.resourceId + '.fields.' + _cTicketsField.name + '.label',
            description: 'RESOURCES.' + this.resourceId + '.fields.' + _cTicketsField.name + '.tooltip',
            collapsible: false,
            fields: [_cTicketsField],
            condition: '!$form.price_on_request',
        }))

        for(let _experienceType of ['activity','package','event']) {
            let _cAvailabilityField = this.formFlow.states['disponibilita'].form.formGroups[0].fields.pop()
            this.formFlow.states['disponibilita'].form.formGroups.push(new PgFormGroup({
                title: 'RESOURCES.' + this.resourceId + '.fields.' + _cAvailabilityField.name + '.label',
                description: 'RESOURCES.' + this.resourceId + '.fields.' + _cAvailabilityField.name + '.tooltip',
                collapsible: false,
                fields: [_cAvailabilityField],
                condition: '$form.type == \'' + _experienceType + '\''
            }))
        }
        
        this.formFlow.states['dati_generali'].form.getFieldLayout('subcategory').condition = '$self.options.some((element) => { return element.data.category == $form.category })';
    
        this.formFlow.states['descrizione'].form.getFieldLayout('parent').condition = '$form.type == "event"';

        this.formFlow.states['disponibilita'].form.getFieldLayout('bookable').display = { fullWidth: false }

        this.formFlow.states['disponibilita'].form.getFieldLayout('booking').condition = '$form.bookable';
        this.formFlow.states['disponibilita'].form.getFieldLayout('booking_notification').condition = '$form.bookable';
        this.formFlow.states['disponibilita'].form.getFieldLayout('mandatory_email').condition = '$form.bookable';
        this.formFlow.states['disponibilita'].form.getFieldLayout('open_event').condition = '$form.bookable';
        this.formFlow.states['disponibilita'].form.getFieldLayout('maximum_participants').condition = '$form.bookable && !$form.open_event';
        this.formFlow.states['disponibilita'].form.getFieldLayout('overbooking').condition = '$form.bookable && !$form.open_event';
        this.formFlow.states['disponibilita'].form.getFieldLayout('minimum_guaranteed').condition = '$form.bookable';
        this.formFlow.states['disponibilita'].form.getFieldLayout('booking_deadline').condition = '$form.bookable';
        this.formFlow.states['disponibilita'].form.getFieldLayout('cancellation_deadline').condition = '$form.bookable';
        this.formFlow.states['disponibilita'].form.getFieldLayout('instant_confirmation').condition = '$form.bookable';
        this.formFlow.states['disponibilita'].form.getFieldLayout('broker_discount').condition = '$form.bookable';

        this.formFlow.states['pagamento'].form.getFieldByName('bookable').visible = false;
        this.formFlow.states['pagamento'].form.getFieldByName('price_on_request').visible = false;
        this.formFlow.states['pagamento'].form.getFieldByName('tickets').visible = false;
        

        this.formFlow.states['pagamento'].form.getFieldLayout('payment_not_applicable').condition = '!$form.bookable || $form.price_on_request || $form.tickets == null || tryParseJSON($form.tickets).fullPrice == null || tryParseJSON($form.tickets).fullPrice == 0';

        this.formFlow.states['pagamento'].form.getFieldLayout('payment').condition = '$form.bookable && !$form.price_on_request && $form.tickets != null && tryParseJSON($form.tickets).fullPrice > 0';
        this.formFlow.states['pagamento'].form.getFieldLayout('payment_method').condition = '$form.bookable && !$form.price_on_request && $form.tickets != null && tryParseJSON($form.tickets).fullPrice > 0 && $form.payment != null && tryParseJSON($form.payment).indexOf("sul_posto") != -1';
        this.formFlow.states['pagamento'].form.getFieldLayout('iban').condition = '$form.bookable && !$form.price_on_request && $form.tickets != null && tryParseJSON($form.tickets).fullPrice > 0 && $form.payment != null && tryParseJSON($form.payment).indexOf("bonifico") != -1';
        this.formFlow.states['pagamento'].form.getFieldLayout('swift').condition = '$form.bookable && !$form.price_on_request && $form.tickets != null && tryParseJSON($form.tickets).fullPrice > 0 && $form.payment != null && tryParseJSON($form.payment).indexOf("bonifico") != -1';

        let _fieldsList:Array<ConfigFormLayoutGroupField> = []

        while(this.formFlow.states['disponibilita'].form.formGroups[0].fields.length > 5) { // a partire dal 5 campo vengono messi alla fine, vedi sopra
            _fieldsList.unshift(this.formFlow.states['disponibilita'].form.formGroups[0].fields.pop())
        }

        this.formFlow.states['disponibilita'].form.formGroups.push(new PgFormGroup({
            collapsible: false,
            fields: _fieldsList
        }))

        let _accessibilityFields:Array<PgFormField> = [];

        let _groupFields = this.formFlow.states['descrizione'].form.formGroups[0].fields

        for(let _name of ['physical_accessibility','cognitive_accessibility','blindness_accessibility','deafness_accessibility','accessibility_description@ExperienceTranslation']) {
            let _field = this.formFlow.states['descrizione'].form.getFieldByName(_name)

            _groupFields.splice(_groupFields.indexOf(_field), 1)

            _accessibilityFields.push(_field)
        }

        this.formFlow.states['descrizione'].form.formGroups.push(new PgFormGroup({
            title: 'RESOURCES.' + this.resourceId + '.fields.accessibility.label',
            description: 'RESOURCES.' + this.resourceId + '.fields.accessibility.tooltip',
            collapsible: false,
            fields: _accessibilityFields
        }))
    }

    mapImportData(data:any) {
        let _retVal = JSON.parse(JSON.stringify(data));
        return _retVal;
    }

    adaptLoadData(values:any) {
        values = super.adaptLoadData(values);

        if(values.template) {
            values.bookable = false;
        }

        this._lastPoi = null;
        this._lastTimezone = null;

        if(this.certificateOptions != null) {
            if(values.template) {
                delete this.formFlow.states['certificate'];
                let _cSteps = this.formFlow.steps as Array<string>;
                _cSteps.splice(_cSteps.indexOf('certificate'))
                
                for(let i in this.formFlow.states) {
                    if(this.formFlow.states[i].nextState == 'certificate') this.formFlow.states[i].nextState = null;
                }
            }
            else {
                for(let i = 0; i < this.certificateOptions.checks.length; i++) {
                    values['certificate_' + i] = values.certificate;
                }
            }
        }

        if(this.environmentService.environment.StripeAPIKey == null || this.environmentService.environment.StripeAPIKey == '') {
            let _cPaymentParsed = null;
            try {
                _cPaymentParsed = PGUtilities.tryParseJSON(values.payment);
            }
            catch(ex) {}
            
            if(_cPaymentParsed != null) {
                if(typeof _cPaymentParsed == 'string') {
                    if(_cPaymentParsed == 'online') {
                        values.payment = null;
                    }
                }
                else if (typeof _cPaymentParsed == 'object') {
                    let _cIndex = _cPaymentParsed.indexOf('online');
                    if(_cIndex != -1) {
                        _cPaymentParsed.splice(_cIndex, 1)
                        if(_cPaymentParsed.length == 0) {
                            values.payment = null;
                        }
                        else {
                            values.payment = JSON.stringify(_cPaymentParsed);
                        }
                    }
                }
            }
        }

        if(values.bank_transfer_method != null) {
            values.iban = values.bank_transfer_method.iban
            values.swift = values.bank_transfer_method.swift
        }

        for(let _type of ['activity','package','event']) {
            if(values.type == _type) {
                values['availability_' + _type] = values.availability
            }
            else {
                values['availability_' + _type] = null;
            }
        }

        delete values.availability;

        let _bookingNotificationString = null;
        let _bookingNotificationObject = PGUtilities.tryParseJSON(values.booking_notification)
        
        if(_bookingNotificationObject != null) {
            if(_bookingNotificationObject.user && _bookingNotificationObject.supplier) {
                _bookingNotificationString = 'both'
            }
            else if(_bookingNotificationObject.user) {
                _bookingNotificationString = 'user'
            }
            else if(_bookingNotificationObject.supplier) {
                _bookingNotificationString = 'supplier'
            }
        }

        values.booking_notification = _bookingNotificationString

        return values;
    }

    afterInit() {
        let _id = this.elementId;
        if(this.dataService.isDraftId(_id)) _id = null;

        if(this.environmentService.environment.CheckoutURL != null) {
            this.formFlow.states['disponibilita'].form.getFieldByName('booking').placeholder = this.environmentService.environment.CheckoutURL + '/book-experience/' + (_id || 'id');
        }
        else {
            this.formFlow.states['disponibilita'].form.getFieldByName('booking').placeholder = null;
        }

        for(let _option of this.formFlow.states['descrizione'].form.getFieldByName('parent').options) {
            _option.hidden = _option.value == _id;
        }
    }

    adaptSaveData(values:any) {
        values = super.adaptSaveData(values);

        if(values.online || values.poi_id != null) {
            values.coordinates = null;
            values.geolocation = null;
            values.region = null;
            values.province = null;
            values.city = null;
            values.address = null;
            values.zipcode = null;
        }

        if(values.online) {
            values.poi_id = null;
        }

        if(this.certificateOptions != null) {
            let _allChecked = true;

            for(let i = 0; i < this.certificateOptions.checks.length; i++) {
                _allChecked = _allChecked && values['certificate_' + i];
            }

            values.certificate = _allChecked;
        }

        if(values.iban != null || values.swift != null) {
            values.bank_transfer_method = { iban: values.iban, swift: values.swift }
            if(typeof values.iban != 'undefined') delete values.iban;
            if(typeof values.swift != 'undefined') delete values.swift;
        }


        for(let _type of ['activity','package','event']) {
            if(values.type == _type) {
                values.availability = values['availability_' + _type];
            }

            delete values['availability_' + _type];
        }

        values.slot_duration = null;
        
        try {
            values.slot_duration = PGUtilities.tryParseJSON(values.availability)[0].slotDuration
        }
        catch(ex) {}

        if(values.booking == '') values.booking = null;

        let _bookingNotificationString = values.booking_notification;
        let _bookingNotificationObject = { user: false, supplier: false }
        
        if(_bookingNotificationString == 'both') {
            _bookingNotificationObject.user = true;
            _bookingNotificationObject.supplier = true;
        }
        else if(_bookingNotificationString == 'user') {
            _bookingNotificationObject.user = true;
        }
        else if(_bookingNotificationString == 'supplier') {
            _bookingNotificationObject.supplier = true;
        }

        values.booking_notification = _bookingNotificationObject

        return values;
    }

    private _lastPoi = null;
    private _lastPoiTimeout = null;

    onValuesChange(values) {
        super.onValuesChange(values);

        if(values.poi_id != this._lastPoi) {
            this._lastPoi = values.poi_id;
    
            values.geolocation = null;

            clearTimeout(this._lastPoiTimeout)
            this._lastPoiTimeout = setTimeout(() => { // non sono riuscito a risolvere diversamente dal mettere una setTimeout...
                if(this._lastPoi == null) {
                    this.formFlow.states.dati_generali.form.setData({ geolocation: { _forceChanges: true } })
                }
                else {
                    for(let _POI of this._POIOptions) {
                        if(_POI.value == this._lastPoi) {
                            values.geolocation = _POI.geolocation
                            values.geolocation._forceChanges = true; // ...e non sono riuscito a risolvere diversamente dal fare questo hack per triggerare la change sul campo
                            this.formFlow.states.dati_generali.form.setData(values)

                            super.onValuesChange(values);

                            this._checkTimezone(values);

                            break;
                        }
                    }

                }
            }, 100)
        }
        else {
            this._checkTimezone(values);
        }
    }

    private _lastTimezone = null;

    _checkTimezone(data) {
        if(data.timezone != this._lastTimezone) {
            this._lastTimezone = data.timezone;

            this.formFlow.states.disponibilita.form.getFieldByName('availability_event').timezone = data.timezone;
            this.formFlow.states.disponibilita.form.getFieldByName('availability_package').timezone = data.timezone;
            this.formFlow.states.disponibilita.form.getFieldByName('availability_activity').timezone = data.timezone;
        }
    }

    @ViewChild('createExperienceSupplierModal') createExperienceSupplierModal:ElementRef;

    private _modalRef:NgbModalRef;

    createModalData:any = null;

    private _createSupplier() {
        this.dataService.createDraftElement(this.resourceId).subscribe((data) => {
            this.createModalData = data;

            this._modalRef = this.modalService.open(this.createExperienceSupplierModal, { size: 'xl' })
        })
    }

    onCreateExperienceSupplierModalReturn(data:any) {
        this._modalRef.close();

        this.dataService.deleteDraftElement(this.resourceId, this.createModalData.id).subscribe(() => {}, () => {})

        if(data.action == 'insert') {
            this._loadSupplierOptions().then(() => {
                this.formFlow.states['dati_generali'].form.setData({ supplier: 'ExperienceSupplier_' + data.id })
             }, () => { })
        }
    }
}

