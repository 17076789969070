import { Injectable } from '@angular/core';
import { Observable, Subscriber } from 'rxjs';

import { HttpClient, HttpRequest, HttpEvent, HttpEventType, HttpHeaderResponse, HttpHeaders } from '@angular/common/http';

import * as moment from 'moment-timezone';
import { WebTour } from '../models/webtour.model';
import { PersistenceService } from './persistence.service';
import { TimetableData } from '../models/timetable.model';
import { map } from 'rxjs/operators';
import { PgHelpTicket } from '../models/pg-help.model';
import { PGUtilities } from '../pg-utilities';
import { EnvironmentService, RealmData } from './environment.service';
import { PgPostFileData } from '../models/file.model';

export class DataProgress {
    progress: number = 0;
    complete: boolean = false; 
    data:any = null;
    error:HttpHeaderResponse = null;

    constructor(progress:number, complete?:boolean, data?:any, error?:HttpHeaderResponse) {
        this.progress = progress;
        if(complete != null) this.complete = complete;
        if(data != null) this.data = data;
        if(error != null) this.error = error;
    }
}

export class DataFilter {
    constructor(public field: string, 
        public operator: '==' | '!=' | '<' | '>' | '<=' | '>=' | 'contains' | 'startsWith' | 'in' | 'between', 
        public value:Array<any>,
        public resource?:string) {}
}

export class DataOrder {
    constructor(public field?: string, 
        public direction?: 'asc' | 'desc',
        public resource?:string) {}
}

export class PgFilterStatusDataFilter extends DataFilter {
    readonly? :boolean;
}

export class PgFilterStatusDataOrder extends DataOrder {
    readonly? :boolean;
}

export class PgFilterStatus {
    search: string = null;
    tags?: string = null;
    system_tags?: string = null;
    filter: Array<PgFilterStatusDataFilter> = [];
    order: Array<PgFilterStatusDataOrder> = [];
}

export class ModelFieldData {
    name:string = null;
    type:'boolean' | 'date' | 'time' | 'datetime' | 'decimal' | 'float' | 'integer' | 'string' | 'text' | 'blob' = null;
    readonly:boolean = null;
    locale:boolean = null;
    map:{
        multiple: boolean, 
        values: {
            [value:string]: string 
        }
    } = null;
}

export class ModelsData {
    [resource:string]: Array<ModelFieldData>
}

export class RelationData {
    public type:'1:1' | 'N:1' | '1:N' | 'N:N';
    public modelA:string = null;
    public modelB:string = null;
    public joinField:string = null;
}

export class ConfigData {
    public id:string = null;
    public modello:string = null;
    public tipo:string = null;
    public data:string = null;
}

export class PermissionData {
    public id:string = null;
    public model:string = null;
    public role_id:string = null;
    public fields?:string  = null;
    public filter?:string = null;
    public list?:boolean = null;
    public view?:boolean = null;
    public edit?:boolean = null;
    public create?:boolean = null;
    public delete?:boolean = null;
    public ignore_user_group?:boolean = null;
}

export class RoleData {
    public id:string = null;
    public name:string = null;
}

export class GroupData {
    public id:string = null;
    public label:string = null;
}

export class AvailabilityData {
    public start:string = null;
    public end:string = null;
    public status:string = null;
}

export class GetResourceDataOptions {
    public with?:Array<string>;
    public view?:string;
    public search?:string;
    public tags?:string;
    public system_tags?:string;
    public filter?:Array<DataFilter>;
    public order?:Array<DataOrder>;
    public offset?:number;
    public limit?:number;
}

export class GetElementSnapshotsOptions {
    public offset?:number;
    public limit?:number;
}

export class UserRealmData {
    user_id:string
    realm_id:string
    status:string
    privacy_policy:0|1
    terms_and_conditions:0|1
    supplier_terms_conditions:0|1
    id:string
}

export class UserDataRealm extends RealmData {
    pivot:UserRealmData = null;

    constructor(fromData?:any) {
        super(fromData)

        if(fromData != null) {
            if(fromData.pivot != null) {
                this.pivot = fromData.pivot
            }
        }
    }
}

export class UserDataRole {
    id:string
    name:string
    pivot: {
        user_id:string,
        role_id:string,
        realm_id:string,
        id:string
    }
}

export class UserDataGroup {
    id:string
    label:string
    pivot: {
        user_id:string,
        group_id:string,
        id:string
    }
}

export class UserLabel {
    id:string
	name:string
	surname:string
}

export class UserData extends UserLabel {
    password?:string
	email:string
    phone:string
	superadmin:1|0
	telephone:string
	language:string
    stripe_id:string
	privacy_policy:1|0
	terms_and_conditions:1|0
    supplier_terms_conditions:1|0
	current_realm: string
    current_role: string
    related_realm:Array<UserDataRealm>
    related_role:Array<UserDataRole>
	related_group:Array<UserDataGroup>
}

export class UserProfileData {
    id:string
    user_id:string
    customer_care:boolean
    customer_care_languages:string
    preferred_channel:string
}

export class SnapshotData {
    id: string
    user_id: string
    resource_id: string
    resource_type: string

    created_at: string
    updated_at: string

    user: { 
        id: string, 
        name: string, 
        surname: string 
    }

    product: any
}

export type ResourceMode = 'data'|'public'|'admin';

@Injectable({
    providedIn: 'root'
})
export class DataService {
    //isCacheEnabled = false;

    selectLimit = 10;

    public status = {
        inProgress: 0
    }

    constructor(private http:HttpClient, private persistenceService:PersistenceService) { 
    }
    
    private environmentService:EnvironmentService = null;

    init(environmentService:EnvironmentService) {
        this.environmentService = environmentService;
    }

    getRealms() {
        return this.getResourceData('Realm', { limit: 5000 }, null, null, 'public', true) as Observable<Array<RealmData>>
    }

    requestOTP(contact:string, type:'email'|'phone') {
        return new Observable<any>((observer) => {
            this.http.post(this.environmentService.environment.APIUrl + '/otp/' + type + '/' + this.cleanRecipient(contact, type), null).subscribe(() => {
                observer.next();
                observer.unsubscribe();
            }, (error) => {
                observer.error(error);
                observer.unsubscribe();
            })
        })
    }

    doLoginOTP(contact:string, type:'email'|'phone', password:string) {
        return new Observable<any>((observer) => {
            this.http.post(this.environmentService.environment.APIUrl + '/otp/login', { contact: this.cleanRecipient(contact, type), otp: password }).subscribe((data: any) => {
                observer.next(data);
                observer.unsubscribe();
            }, (error) => {
                observer.error(error);
                observer.unsubscribe();
            })
        })
    }

    doLoginCode(code:string) {
        return new Observable<any>((observer) => {
            this.http.post(this.environmentService.environment.APIUrl + this.environmentService.environment.LoginCodePath.replace('{{code}}', code), null).subscribe((data: any) => {
                observer.next(data);
                observer.unsubscribe();
            }, (error) => {
                observer.error(error);
                observer.unsubscribe();
            })
        })
    }

    doLogin(contact:string, password:string) {
        return new Observable<any>((observer) => {
            this.http.post(this.environmentService.environment.APIUrl + '/login', { email: contact, password: password }).subscribe((data: any) => {
                observer.next(data);
                observer.unsubscribe();
            }, (error) => {
                observer.error(error);
                observer.unsubscribe();
            })
        })
    }

    doLogout() {
        return new Observable<any>((observer) => {
            this.http.post(this.environmentService.environment.APIUrl + '/logout', {}).subscribe((data: any) => {
                observer.next();
                observer.unsubscribe();
            }, (error) => {
                observer.next(); // ignoring errors
                observer.unsubscribe();
            })
        })
    }

    refreshToken(token:string) {
        return new Observable<any>((observer) => {
            this.http.post(this.environmentService.environment.APIUrl + '/refresh?refresh_token=' + encodeURIComponent(token), null).subscribe((data:any) => {
                observer.next(data);
                observer.unsubscribe();
            }, (error) => {
                observer.error(error);
                observer.unsubscribe();
            })
        })
    }

    userAccept(data:any, realm?:string) {
        if(realm != null) {
            return new Observable<any>((observer) => {
                data.realm_id = realm;
                this.http.post(this.environmentService.environment.APIUrl + '/me/acceptRealm', data).subscribe((data: any) => {
                    observer.next(data);
                    observer.unsubscribe();
                }, (error) => {
                    observer.error(error);
                    observer.unsubscribe();
                })
            })
        }
        else {
            return new Observable<any>((observer) => {
                this.http.post(this.environmentService.environment.APIUrl + '/me/accept', data).subscribe((data: any) => {
                    observer.next(data);
                    observer.unsubscribe();
                }, (error) => {
                    observer.error(error);
                    observer.unsubscribe();
                })
            })
        }
    }

    doSessionRefresh() {
        return new Observable<any>((observer) => {
            this.http.post(this.environmentService.environment.APIUrl + '/refresh', {}).subscribe((data: any) => {
                observer.next(data);
                observer.unsubscribe();
            }, (error) => {
                observer.error();
                observer.unsubscribe();
            })
        })
    }

    getUserData() {
        return new Observable<UserData>((observer) => {
            this.http.get(this.environmentService.environment.APIUrl + '/me').subscribe((data:any) => {
                observer.next(data);
                observer.unsubscribe();
            }, (error) => {
                observer.next(null);
                observer.unsubscribe();
            })
        })
    }

    setUserData(data:Partial<UserData>) {
        return new Observable<UserData>((observer) => {
            this.http.put(this.environmentService.environment.APIUrl + '/me', data).subscribe((data:any) => {
                observer.next(data);
                observer.unsubscribe();
            }, (error) => {
                observer.next(null);
                observer.unsubscribe();
            })
        })
    }

    getUserProfile() {
        return new Observable<UserProfileData>((observer) => {
            this.http.get(this.environmentService.environment.APIUrl + '/me/profile').subscribe((data:any) => {
                observer.next(data);
                observer.unsubscribe();
            }, (error) => {
                observer.next(null);
                observer.unsubscribe();
            })
        })
    }

    setUserProfile(data:Partial<UserProfileData>) {
        return new Observable<UserProfileData>((observer) => {
            this.http.put(this.environmentService.environment.APIUrl + '/me/profile', data).subscribe((data:any) => {
                observer.next(data);
                observer.unsubscribe();
            }, (error) => {
                observer.next(null);
                observer.unsubscribe();
            })
        })
    }

    getUserAvailability() {
        return new Observable<Array<TimetableData>>((observer) => {
            this.http.get(this.environmentService.environment.APIUrl + '/me/availability', {
                headers:  { 'X-Interceptor-Silent': '404' }
            }).subscribe((data:any) => {
                observer.next(data);
                observer.unsubscribe();
            }, (error) => {
                observer.next(null);
                observer.unsubscribe();
            })
        })
    }

    setUserAvailability(data:Array<TimetableData>) {
        return new Observable<Array<TimetableData>>((observer) => {
            this.http.put(this.environmentService.environment.APIUrl + '/me/availability', data).subscribe((data:any) => {
                observer.next(data);
                observer.unsubscribe();
            }, (error) => {
                observer.next(null);
                observer.unsubscribe();
            })
        })
    }

    sendHelpTicket(ticket:PgHelpTicket) {
        return new Observable<any>((observer) => {
            let _errorLogData = {
                list: []
            }

            for(let _error of ticket.httpErrorLog) {
                let _responseErrorData = null;

                if(_error.response.error != null) {
                    if(typeof _error.response.error == 'string') {
                        _responseErrorData = _error.response.error;
                    }
                    else {
                        _responseErrorData = {
                            exception: _error.response.error.exception,
                            file: _error.response.error.file,
                            line: _error.response.error.line,
                            message: _error.response.error.message
                        };
                    }
                }

                _errorLogData.list.push({
                    time: _error.time,
                    type: 'http',
                    request: {
                        method: _error.request.method,
                        url: _error.request.url,
                        body: _error.request.body
                    },
                    response: {
                        status: _error.response.status,
                        error: _responseErrorData
                    }
                })
            }

            /*for(let _error of ticket.jsErrorLog) {
                _errorLogData.list.push({
                    time: _error.time,
                    type: 'js',
                    error: _error.error
                })
            }*/

            _errorLogData.list.sort((a, b) => {
                if(a.time < b.time) return -1;
                else if(a.time > b.time) return 1;
                else return 0;
            })

            let _ticketData:any = {
                info: ticket.info,
                error_log: _errorLogData,
            }

            for(let i in ticket) {
                if(typeof ticket[i] != 'object' && typeof ticket[i] != 'function') {
                    if(typeof _ticketData[i] == 'undefined') {
                        _ticketData[i] = ticket[i]
                    }
                }
            }

            this.http.post(this.environmentService.environment.APIUrl + '/help/ticket', _ticketData).subscribe((data: any) => {
                observer.next(data);
                observer.unsubscribe();
            }, (error) => {
                observer.error(error);
                observer.unsubscribe();
            })
        })
    }

    getRequest(queryURL:string, options?:any) {
        return this.http.get(this.environmentService.environment.APIUrl + queryURL, options || {}) as Observable<any>
    }

    postRequest(queryURL:string, sendData:any, options?:any) {
        return this.http.post(this.environmentService.environment.APIUrl + queryURL, sendData, options || {}) as Observable<any>
    }

    putRequest(queryURL:string, sendData:any, options?:any) {
        return this.http.put(this.environmentService.environment.APIUrl + queryURL, sendData, options || {}) as Observable<any>
    }

    deleteRequest(queryURL:string, options?:any) {
        return this.http.delete(this.environmentService.environment.APIUrl + queryURL, options || {}) as Observable<any>
    }

    normalizeResourceName(resourceName:string) {
        return resourceName.charAt(0).toLowerCase() + resourceName.substring(1);
    }

    getResourceFieldName(resourceName:string) {
        return resourceName.replace(/[A-Z]/g, (match) => { return '_' + match.toLowerCase()}).replace(/^_/, '');    
    }

    getModelsData():Observable<ModelsData> {
        return this.getRequest('/model') as Observable<ModelsData>;
    }

    getRelationData():Observable<Array<RelationData>> {
        return this.getRequest('/relation') as Observable<Array<RelationData>>;
    }

    getRolesData(mode?:ResourceMode):Observable<Array<RoleData>> {
        return this.getResourceData('Role', { limit: 1000, order: [{ field: 'id', direction: 'asc' }] }, null, null, mode) as Observable<Array<RoleData>>;
    }

    getGroupsData():Observable<Array<GroupData>> {
        return this.getResourceData('Group', { limit: 1000 }) as Observable<Array<GroupData>>;
    }

    getConfigData():Observable<Array<ConfigData>> {
        return this.getResourceData('Configuration', { limit: 1000 }).pipe(map((data) => {
            for(let _item of data) {
                if(_item.tipo == 'resource') {
                    let _config = JSON.parse(_item.data);

                    if(_config.version == null) _config.version = 0;

                    if(_config.version < 1) {
                        if(typeof _config.views != 'undefined') {
                            _config.view = null;

                            if(_config.views != null && _config.views[0] != null) {
                                _config.view = _config.views[0];
                                delete _config.view.id;
                            }

                            delete _config.views;
                        }

                        if(typeof _config.filters != 'undefined') {
                            delete _config.filters;
                        }
                    }

                    if(_config.version != 1) {
                        _item.data = JSON.stringify(_config)
                        _config.version = 1;
                    }
                }
            }

            return data;
        })) as Observable<Array<ConfigData>>;
    }

    saveConfigData(element:string, sendData:ConfigData) {
        if(element == null || element == '') return this.postConfigData(sendData);
        else return this.putConfigData(element, sendData)
    }

    postConfigData(sendData?:ConfigData) {
        return this.postResourceData('Configuration', sendData);
    }

    putConfigData(element:string, sendData:ConfigData) {
        return this.putElementData('Configuration', element, sendData);
    }

    deleteConfigData(element:string) {
        return this.deleteElement('Configuration', element);
    }

    getPermissionData(isAdmin?:boolean):Observable<Array<PermissionData>> {
        // WIP: /me/permission
        
        //if(isAdmin) {
            return this.getResourceData('Permission', { limit: 1000 }) as Observable<Array<PermissionData>>;
        /*}
        else {
            return new Observable<Array<PermissionData>>((observer) => {
                this.status.inProgress++;
    
                this.getRequest('/me/permission').subscribe(data => {
                    this.status.inProgress--;
    
                    if(data == null) data = [];
    
                    observer.next(data as Array<any>);
                    observer.unsubscribe();
                }, error => {
                    this.status.inProgress--;
    
                    console.debug('DATASERVICE - getPermissionData error:', error);
    
                    observer.next([]);
                    observer.unsubscribe();
                });
            })
        }*/
    }

    savePermissionData(element:string, sendData:PermissionData) {
        if(element == null || element == '') return this.postPermissionData(sendData);
        else return this.putPermissionData(element, sendData)
    }

    postPermissionData(sendData?:PermissionData) {
        return this.postResourceData('Permission', sendData);
    }
    
    putPermissionData(element:string, sendData:PermissionData) {
        return this.putElementData('Permission', element, sendData);
    }

    deletePermissionData(element:string) {
        return this.deleteElement('Permission', element);
    }

    private observableChainRec(observables:Array<Observable<any>>, observer:Subscriber<any>, index?:number) {
        if(index == null) index = 0;

        if(observables.length <= index) {
            observer.next();
            observer.unsubscribe();
        }
        else {
            observables[index].subscribe(() => {
                index++;
                this.observableChainRec(observables, observer, index);
            })
        }
    }

    observableChain(observables:Array<Observable<any>>) {
        return new Observable<any>((observer) => {
            this.observableChainRec(observables, observer)
        })
    }

    private _resourceFilters:{ 
        [resource:string]: {
            [filter:string]: Array<DataFilter>
        }
    } = {
        'Experience': {
            'Event': [{
                field: 'type', operator: '==', value: ['event']
            }],
            'Template': [{
                field: 'template', operator: '==', value: [true]
            }],
            '': [{
                field: 'type', operator: '!=', value: ['event']
            }, {
                field: 'template', operator: '!=', value: [true]
            }]
        }
    }

    getCleanResourceId(resource:string) {
        if(resource != null) {
            return resource.replace(/~.*$/, '');
        }
    }

    getResourceFilter(resource:string) {
        let _filterName = resource.split('~')[1];
        if(_filterName == null) _filterName = '';
        
        resource = this.getCleanResourceId(resource)

        if(this._resourceFilters[resource] != null && this._resourceFilters[resource][_filterName] != null) {
            return this._resourceFilters[resource][_filterName];
        }
    }

    getResourceQueryURL(resource:string, options?:GetResourceDataOptions, relResource?: string, relElement?: string) {
        let _queryURL = '';

        let _resourceFilter = this.getResourceFilter(resource)
        resource = this.getCleanResourceId(resource)

        if(_resourceFilter != null) {
            if(options == null) options = {}
            if(options.filter == null) options.filter = []

            for(let _item of _resourceFilter) {
                options.filter.push(_item)
            }
        }


        if(relResource != null && relElement != null) {
            _queryURL += encodeURIComponent(this.normalizeResourceName(relResource)) + '/' + relElement + '/';
        }

        _queryURL += encodeURIComponent(this.normalizeResourceName(resource));

        let _searchVal = this.getDataOptionsSearchString(options)

        if(_searchVal != '') _queryURL += '?' + _searchVal;

        return _queryURL;
    }

    private _getWithQuery(withList:Array<string>) {
        if(withList == null || withList.length == 0) return '';
        else {
            let _withQuery = '';
            for(let _cWith of withList) {
                if(_withQuery != '') _withQuery += ',';

                _withQuery += 'related_' + this.normalizeResourceName(_cWith);
            }

            return 'with=' + encodeURIComponent(_withQuery);
        }
    }

    getDataOptionsSearchString(options:GetResourceDataOptions) {
        let _searchVal = '';

        if(options != null) {
            if(options.with != null && options.with.length > 0) {
                if(_searchVal != '') _searchVal += '&';
    
                _searchVal += this._getWithQuery(options.with)
            }
    
            if(options.search != null && options.search != '') {
                if(_searchVal != '') _searchVal += '&';
                _searchVal += 'search=' + encodeURIComponent(options.search);
            }

            if(options.tags != null && options.tags != '') {
                if(_searchVal != '') _searchVal += '&';
                _searchVal += 'tags=' + encodeURIComponent(options.tags);
            }

            if(options.system_tags != null && options.system_tags != '') {
                if(_searchVal != '') _searchVal += '&';
                _searchVal += 'system_tags=' + encodeURIComponent(options.system_tags);
            }
    
            if(options.filter != null && options.filter.length > 0) {
                if(_searchVal != '') _searchVal += '&';
    
                let _cFilter = options.filter;
    
                _searchVal += 'filter=' + encodeURIComponent(JSON.stringify(_cFilter));
            }
    
            if(options.order != null && options.order.length > 0) {
                if(_searchVal != '') _searchVal += '&';
    
                let _cOrder = options.order;
    
                _searchVal += 'order=' + encodeURIComponent(JSON.stringify(_cOrder));
            }
    
            if(options.offset != null) {
                if(_searchVal != '') _searchVal += '&';
                _searchVal += 'offset=' + encodeURIComponent(options.offset.toString());
            }
    
            if(options.limit != null) {
                if(_searchVal != '') _searchVal += '&';
                _searchVal += 'limit=' + encodeURIComponent(options.limit.toString());
            }
    
            if(options.view != null) {
                if(_searchVal != '') _searchVal += '&';
                _searchVal += 'view=' + encodeURIComponent(options.view);
            }
        }

        return _searchVal;
    }

    getResourceData(resource:string, options?:GetResourceDataOptions, relResource?: string, relElement?: string, mode?:ResourceMode, silent?:boolean):Observable<Array<any>> {
        if(options == null) options = {};

        return new Observable<Array<any>>((observer) => {
            let _queryURL = '/' + (mode != null ? mode : 'data') + '/' + this.getResourceQueryURL(resource, options, relResource, relElement)

            let _headers:HttpHeaders = null
            if(silent) {
                _headers = new HttpHeaders({ 'X-Interceptor-Silent': '*' })
            }              

            this.status.inProgress++;

            this.getRequest(_queryURL, { 
                headers: _headers
            }).subscribe(data => {

                this.status.inProgress--;

                if(data == null) data = [];

                observer.next(data as Array<any>);
                observer.unsubscribe();
            }, error => {
                this.status.inProgress--;

                console.debug('DATASERVICE - getResourceData error:', error);

                observer.next([]);
                observer.unsubscribe();
            });
        })
    }

    getElementData(resource:string, element:string, withRel?:Array<string>, mode?:ResourceMode, silent?:boolean):Observable<any> {

        if(this.isDraftId(element)) {
            return this.getDraftElementData(resource, element)
        }
        else {
            return new Observable<any>((observer) => {
                resource = this.getCleanResourceId(resource)

                let _queryURL = '/' + (mode != null ? mode : 'data') + '/' + this.normalizeResourceName(resource) + '/' + element;
            
                if(withRel != null && withRel.length > 0) {
                    _queryURL += '?' + this._getWithQuery(withRel);
                }

                let _headers:HttpHeaders = null
                if(silent) {
                    _headers = new HttpHeaders({ 'X-Interceptor-Silent': '*' })
                }
                
                this.status.inProgress++;

                this.getRequest(_queryURL, { 
                    headers: _headers
                }).subscribe(data => {

                    this.status.inProgress--;

                    observer.next(data);
                    observer.unsubscribe();
                }, error => {
                    this.status.inProgress--;

                    console.debug('DATASERVICE - getElementData error:', error);

                    observer.next(null);
                    observer.unsubscribe();
                });
            })
        }
    }

    getElementSnapshots(resource:string, element:string, options?:GetElementSnapshotsOptions, silent?:boolean):Observable<Array<any>> {
        resource = this.getCleanResourceId(resource)

        return new Observable<any>((observer) => {  
            resource = this.getCleanResourceId(resource)

            let _queryURL = '/snapshot/' + this.normalizeResourceName(resource) + '/' + element; 

            let _searchVal = ''

            if(options != null) {
                if(options.offset != null) {
                    if(_searchVal != '') _searchVal += '&';
                    _searchVal += 'offset=' + encodeURIComponent(options.offset.toString());
                }
        
                if(options.limit != null) {
                    if(_searchVal != '') _searchVal += '&';
                    _searchVal += 'limit=' + encodeURIComponent(options.limit.toString());
                }
            }

            if(_searchVal != '') {
                _queryURL += '?' + _searchVal
            }

            let _headers:HttpHeaders = null
            if(silent) {
                _headers = new HttpHeaders({ 'X-Interceptor-Silent': '*' })
            }
            
            this.status.inProgress++;

            this.getRequest(_queryURL, { 
                headers: _headers
            }).subscribe(data => {

                this.status.inProgress--;

                observer.next(data);
                observer.unsubscribe();
            }, error => {
                this.status.inProgress--;

                console.debug('DATASERVICE - getElementSnapshots error:', error);

                observer.next(null);
                observer.unsubscribe();
            });
        })
    }

    deleteElement(resource:string, element:string, mode?:ResourceMode, options?:any):Observable<boolean> {
        if(this.isDraftId(element)) {
            return this.deleteDraftElement(resource, element)
        }
        else {
            return new Observable<boolean>((observer) => {
                this.status.inProgress++;

                resource = this.getCleanResourceId(resource)

                this.deleteRequest('/' + (mode != null ? mode : 'data') + '/' + this.normalizeResourceName(resource) + '/' + element, options).subscribe(data => {

                    this.status.inProgress--;

                    observer.next(true);
                    observer.unsubscribe();
                }, error => {

                    this.status.inProgress--;

                    observer.next(false);
                    observer.unsubscribe();
                });
            })
        }
    }

    putElementData(resource:string, element:string, sendData:any, mode?:ResourceMode, options?:any):Observable<any> {
        return new Observable<any>((observer) => {
            this.status.inProgress++;

            resource = this.getCleanResourceId(resource)

            this.putRequest('/' + (mode != null ? mode : 'data') + '/' + this.normalizeResourceName(resource) + '/' + element, sendData, options).subscribe(data => {

                this.status.inProgress--;

                observer.next(data);
                observer.unsubscribe();
            }, error => {

                this.status.inProgress--;

                observer.next(null);
                observer.unsubscribe();
            });
        })
    }

    postResourceData(resource:string, sendData?:any, mode?:ResourceMode, options?:any):Observable<any> {
        if(sendData == null) sendData = {};

        return new Observable<string>((observer) => {
            this.status.inProgress++;

            resource = this.getCleanResourceId(resource)

            this.postRequest('/' + (mode != null ? mode : 'data') + '/' + this.normalizeResourceName(resource), sendData, options).subscribe(data => {
                
                this.status.inProgress--;

                observer.next(data as any);
                observer.unsubscribe();
            }, error => {

                this.status.inProgress--;

                observer.next(null);
                observer.unsubscribe();
            });
        })
    }

    private _dateToQueryString(date:Date) {
        let _retVal = date.getFullYear() + '-';

        let _monthVal = (date.getMonth() + 1);
        _retVal += (_monthVal < 10 ? '0' : '') + _monthVal + '-'; 

        let _dayVal = date.getDate();
        _retVal += (_dayVal < 10 ? '0' : '') + _dayVal;

        return _retVal;
    }

    getElementAvailability(resource:string, element:string, from?:Date, toOrLimit?:Date|number):Observable<Array<AvailabilityData>> {

        resource = this.getCleanResourceId(resource)

        let _queryURL = '/data/' + this.normalizeResourceName(resource) + '/' + element + '/availability'

        let _searchVal = '';

        if(from != null) {
            _searchVal = 'from=' + this._dateToQueryString(from) + '-';
        }

        if(toOrLimit != null)  {
            if(_searchVal != '') _searchVal += '&';

            if(typeof toOrLimit == 'number') {
                _searchVal += 'limit=' + toOrLimit;
            }
            else {
                _searchVal += 'to=' + this._dateToQueryString(toOrLimit);
            }
        }

        if(_searchVal != '') _queryURL += '?' + _searchVal;

        return new Observable<Array<AvailabilityData>>((observer) => {
            this.status.inProgress++;

            this.getRequest(_queryURL).subscribe((data:any) => {

                this.status.inProgress--;

                let _timeZone = moment.tz(data.timezone).toISOString().split('Z')[1];

                let _retData:Array<AvailabilityData> = [];

                if(data != null && data.slots != null) {                    

                    for(let _cData of data.slots) {
                        let _cStart = _cData.start_day + 'T' + _cData.start_time + 'Z' + _timeZone;
                        let _cEnd = _cData.end_day + 'T' + _cData.end_time + 'Z' + _timeZone;

                        // verifica correttezza, alcuni giorni mi arrivano con durata 0
                        // TODO: andrebbe fatto lato server, mi dovrebbe tornare un certo numero di risultati buoni

                        let _cStartDate = new Date(_cStart);
                        let _cEndDate = new Date(_cEnd);

                        if(_cEndDate > _cStartDate) {
                            _retData.push({
                                start: _cStartDate.toISOString(),
                                end: _cEndDate.toISOString(),
                                status: _cData.status
                            })
                        }
                    }
                }

                observer.next(_retData);
                observer.unsubscribe();
            }, error => {
                this.status.inProgress--;

                console.debug('DATASERVICE - getElementAvailability error:', error);

                observer.next(null);
                observer.unsubscribe();
            });
        })
    }

    private async _getPostFileInfo(fileObj:File) {
        return new Promise<any>((resolve) => {
            if(/^image/.test(fileObj.type)) {
                let _fr = new FileReader();

                _fr.onload = () => {
                    let _img = new Image()
                    
                    _img.onload = () => {
                        resolve({
                            width: _img.naturalWidth,
                            height: _img.naturalHeight,
                        })
                    }

                    _img.onerror = () => {
                        resolve(null)
                    }

                    _img.src = _fr.result as string
                }

                _fr.onerror = () => {
                    resolve(null)
                }

                _fr.readAsDataURL(fileObj);
            }
            else {
                resolve(null)
            }
        })
    }

    postFile(resource:string, fileObj:File, data?:PgPostFileData, silent?:boolean): Observable<DataProgress> {
        return new Observable<DataProgress>((observer) => {
            this.status.inProgress++;

            let formData = new FormData();

            let _fileName = fileObj.name.replace(/\.[^\.]*$/, (val) => {
                return val.toLowerCase();
            })

            formData.append('fileToUpload', fileObj, _fileName);

            this._getPostFileInfo(fileObj).then((info) => {
                formData.append('fileInfo', JSON.stringify(info));

                if(data != null) {
                    for(let i in data) {
                        if(data[i] != null) {
                            formData.append(i, data[i]);
                        }
                    }
                }

                let _headers:HttpHeaders = null
                if(silent) {
                    _headers = new HttpHeaders({ 'X-Interceptor-Silent': '*' })
                }

                resource = this.getCleanResourceId(resource)

            let req = new HttpRequest('POST', this.environmentService.environment.APIUrl + '/data/' + this.normalizeResourceName(resource), 
                formData, 
                { 
                    reportProgress: true,
                    headers: _headers
                });
          
                this.http.request(req).subscribe((event: HttpEvent<any>) => {

                    switch (event.type) {
                        case HttpEventType.UploadProgress:
                            
                            observer.next(new DataProgress(Math.min(0.99, event.loaded / event.total)));

                            break;
                        case HttpEventType.ResponseHeader:

                            if(!event.ok) { // ERROR
                                this.status.inProgress--;

                                observer.next(new DataProgress(1, true, null, event));

                                observer.unsubscribe();
                            }

                            break;
                        case HttpEventType.Response:

                            this.status.inProgress--;

                            observer.next(new DataProgress(1, true, event.body));
                            observer.unsubscribe();

                            break;
                    }
                })
            })
        });
    }

    viewTour(id:string) {
        return new Observable<WebTour>((observer) => {
            this.getElementData('TourProduct', id).subscribe((data) => {
                let _retVal = new WebTour(data);

                observer.next(_retVal);
                observer.unsubscribe();
            })
        })
    }

    viewTours() {
        return new Observable<Array<WebTour>>((observer) => {
            this.getResourceData('TourProduct').subscribe((data) => {
                let _retVal:Array<WebTour> = [];

                for(let _cData of data) {
                    let _cTour = new WebTour(_cData);

                    _retVal.push(_cTour);
                }

                observer.next(_retVal);
                observer.unsubscribe();
            }, () => {
                observer.next(null);
                observer.unsubscribe();
            })
        })
    }

    getTour(id:string) {
        return new Observable<WebTour>((observer) => {
            this.getElementData('TourProduct', id).subscribe((data) => {
                let _retVal = new WebTour(data);

                observer.next(_retVal);
                observer.unsubscribe();
            })
        })
    }

    getTours() {
        return new Observable<Array<WebTour>>((observer) => {
            this.getResourceData('TourProduct').subscribe((data) => {
                let _retVal:Array<WebTour> = [];

                for(let _cData of data) {
                    let _cWebTour = new WebTour(_cData);

                    _retVal.push(_cWebTour);
                }

                observer.next(_retVal);
                observer.unsubscribe();
            }, () => {
                observer.next(null);
                observer.unsubscribe();
            })
        })
    }

    saveTour(tour:WebTour) {
        return new Observable<WebTour>((observer) => {
            this.putElementData('TourProduct', tour.id, tour).subscribe((data) => {
                let _cData = data;

                this.saveElementTranslations(tour.id, 'TourProductTranslation', 'tour_product_id', data.languages, tour.translations).subscribe((data) => {
                    _cData.translations = data;

                    observer.next(new WebTour(data));
                    observer.unsubscribe();
                }, () => {
                    observer.next(null);
                    observer.unsubscribe();
                })
            }, () => {
                observer.next(null);
                observer.unsubscribe();
            })
        })
    }

    createTour() {
        return this.postResourceData('TourProduct', {
            name: 'Nuovo tour',
            description: 'Nuovo tour'
        }) as Observable<WebTour>;
    }

    deleteTour(id:string) {
        return this.deleteElement('TourProduct', id);
    }

    cleanPhone(phone:string) {
        if(phone == null || phone == '') return null;
        else {
            phone = phone.replace(/[^\d|\+]/g, '')
            phone = phone.replace(/^00/, '+')
            if(!/^\+/.test(phone)) {
                phone = '+39' + phone;
            }
    
            return phone;
        }
    }

    cleanRecipient(recipient:string, via?:'phone'|'email'|'list') {
        if(recipient == null) return null;
        else {
            let _isComposite = false;

            if(via == null) {
                via = recipient.split('/')[0] as 'phone'|'email'|'list';
                recipient = recipient.replace(/^[^\/]*\//, '')
                _isComposite = true;
            }
    
            if(via == 'phone') {
                recipient = this.cleanPhone(recipient)
            }
    
            if(_isComposite) return via + '/' + recipient
            else return recipient;
        }
    }

    sendCustomMessage(via:'phone'|'email'|'list', recipient:string, content:string, subject?:string) {
        return new Observable<boolean>((observer) => {
            recipient = this.cleanRecipient(recipient, via);

            this.postRequest('/messages/single/' + via + '/' + encodeURIComponent(recipient), {
                'subject': subject, 
                'content': content
            }).subscribe((data) => {
                observer.next(true);
                observer.unsubscribe();
            }, (error) => {
                observer.error(error);
                observer.unsubscribe();
            })
        })
    }

    sendShapeupMessage(via:'phone'|'email'|'list', recipient:string, messageId:string, language:string) {        
        return new Observable<boolean>((observer) => {
            recipient = this.cleanRecipient(recipient, via);

            this.postRequest('/messages/single/' + via + '/' + encodeURIComponent(recipient) + '/' + messageId, { 
                'language': language
            }).subscribe((data) => {
                observer.next(true);
                observer.unsubscribe();
            }, (error) => {
                observer.error(error);
                observer.unsubscribe();
            })
        })
    }

    sendWebTourMessage(tourId:string, via:'phone'|'email'|'link', recipient:string) {
        return new Observable<{ link: string }>((observer) => {
            if(via == 'link') {
                this.postRequest('/messages/tour/' + tourId, null).subscribe((data) => {
                    observer.next(data);
                    observer.unsubscribe();
                }, (error) => {
                    observer.error(error);
                    observer.unsubscribe();
                })
            }
            else {
                recipient = this.cleanRecipient(recipient, via);

                this.postRequest('/messages/tour/' + tourId + '/' + via + '/' + encodeURIComponent(recipient), null).subscribe((data) => {
                    observer.next(data);
                    observer.unsubscribe();
                }, (error) => {
                    observer.error(error);
                    observer.unsubscribe();
                })
            }
        })
    }

    sendWebTourDirectorMessage(tourId:string, via:'phone'|'email'|'list', recipient:string) {
        return new Observable<{ token:string }>((observer) => {
            recipient = this.cleanRecipient(recipient, via);

            this.postRequest('/messages/tourDirector/' + via + '/' + encodeURIComponent(recipient) + '/' + tourId, null).subscribe((data) => {
                observer.next(data as { token:string });
                observer.unsubscribe();
            }, (error) => {
                observer.error(error);
                observer.unsubscribe();
            })
        })
    }

    shortURL(url:string) {
        return new Observable<{ url: string, short: string }>((observer) => {
            this.postRequest('/utility/short', { 
                'url': url
            }).subscribe((data) => {
                observer.next(data as { url: string, short: string });
                observer.unsubscribe();
            }, (error) => {
                observer.error(error);
                observer.unsubscribe();
            })
        })
    }

    saveElementData(resource:string, element:string, sendData:any):Observable<any> {
        if(element == null || element == '') {
            return this.postResourceData(resource, sendData)
        }
        else if(this.isDraftId(element)) {
            return this.postDraftElementData(resource, element, sendData)
        }
        else {
            return this.putElementData(resource, element, sendData)
        }
    }

    getElementTranslations(resourceId:string, elementId:string, translationResource:string) {
        return new Observable<{ [language:string]: any }>((observer) => {
            this.getResourceData(translationResource, null, resourceId, elementId).subscribe((translData) => {   
                let _retVal:{ [language:string]: any } = {} 

                for(let _cTransl of translData) {
                    _retVal[_cTransl.language] = _cTransl;
                }
    
                observer.next(_retVal);
                observer.unsubscribe();
            })
        })
    }

    saveElementTranslations(elementId:string, resourceId:string, joinField:string, languages:Array<string>, translations: { [language:string]: any }) {
        return new Observable<{ [language:string]: any }> ((observer) => {

            let _translationList:Array<any> = [];

            for(let _language of this.environmentService.environment.AvailableApplicationLanguages) {
                if(translations[_language] == null) {
                    translations[_language] = {
                        auto: true,
                        language: _language
                    }
                }
                else {
                    if(languages != null) {
                        translations[_language].auto = languages.indexOf(_language) == -1;
                    }
                    else {
                        translations[_language].auto = _language != this.environmentService.environment.AvailableApplicationLanguages[0] // sembra che se non c'è un campo lingue viene tradotto solo nella prima lingua
                    }
                }

                _translationList.push(translations[_language])
            }

            _translationList.sort((a, b) => {
                if(!a.auto && b.auto) return -1;
                else if(a.auto && !b.auto) return 1;
                else return 0;
            })

            let _reqCount = 0;

            let _retVal:{ [language:string]: any } = {};

            for(let _translation of _translationList) {
                if(_translation.id != null) {
                    _reqCount++;
                    this.putElementData(resourceId, _translation.id, _translation).subscribe((data) => {
                        for(let j in data) {
                            _translation[j] = data[j];
                        }

                        _reqCount--;
                        if(_reqCount == 0) {
                            observer.next(_retVal);
                            observer.unsubscribe();
                        }
                    })
                }
                else {
                    let _hasSomethingToSave = false;

                    for(let j in _translation) {
                        if(j != 'language' && j != joinField && _translation[j] != null) {
                            _hasSomethingToSave = true;
                            break;
                        }
                    }

                    if(_hasSomethingToSave) {
                        _reqCount++;
                        _translation[joinField] = elementId;

                        this.postResourceData(resourceId, _translation).subscribe((data) => {
                            for(let j in data) {
                                _translation[j] = data[j];
                            }

                            _reqCount--;
                            if(_reqCount == 0) {
                                observer.next(_retVal);
                                observer.unsubscribe();
                            }
                        })
                    }
                }
            }

            if(_reqCount == 0) {
                observer.next(_retVal);
                observer.unsubscribe();
            }
        })
    }

    isDraftId(id:string|number) {
        return typeof id == 'string' && id.startsWith('draft_')
    }

    generateDraftId() {
       return 'draft_' + PGUtilities.getRandomId();
    }

    getResourceDrafts(resourceId:string) {
        return new Observable<{ [id:string]: any }>((observer) => {
            setTimeout(() => {
                let _cResourceDraftObject = {}
                let _cResourceDraftString = this.persistenceService.getItem('PGDraft_' + resourceId);

                if(_cResourceDraftString != null) {
                    _cResourceDraftObject = JSON.parse(_cResourceDraftString);
                }

                observer.next(_cResourceDraftObject);
                observer.unsubscribe();
            }, 100)
        })
    }

    createDraftElement(resourceId:string, draftData?:any) {
        // teniamo un draft per risorsa per ora
        this.deleteResourceDrafts(resourceId);

        if(draftData == null) draftData = {};

        draftData.id = this.generateDraftId()

        return this.saveDraftElementData(resourceId, draftData.id, draftData);
    }

    getDraftElementData(resourceId:string, element:string) {
        return new Observable<any>((observer) => {
            setTimeout(() => {
                let _retVal = null;

                let _cResourceDraftString = this.persistenceService.getItem('PGDraft_' + resourceId);

                if(_cResourceDraftString != null) {
                    let _cResourceDraftObject = JSON.parse(_cResourceDraftString);
                    if(_cResourceDraftObject[element] != null) {
                        _retVal = _cResourceDraftObject[element];
                    }
                }

                observer.next(_retVal);
                observer.unsubscribe();
            }, 100)
        })
    }

    saveDraftElementData(resourceId:string, element:string, draftData:any) {
        return new Observable<any>((observer) => {
            setTimeout(() => {
                let _cResourceDraftObject = {}
                let _cResourceDraftString = this.persistenceService.getItem('PGDraft_' + resourceId);

                if(_cResourceDraftString != null) {
                    _cResourceDraftObject = JSON.parse(_cResourceDraftString);
                }

                _cResourceDraftObject[element] = draftData;

                this.persistenceService.setItem('PGDraft_' + resourceId, JSON.stringify(_cResourceDraftObject), 'technical');

                observer.next(draftData);
                observer.unsubscribe();
            }, 100)
        })
    }

    deleteResourceDrafts(resourceId:string) {
        this.persistenceService.removeItem('PGDraft_' + resourceId);
    }

    deleteDraftElement(resourceId:string, element:string) {
        return new Observable<boolean>((observer) => {
            setTimeout(() => {
                let _cResourceDraftString = this.persistenceService.getItem('PGDraft_' + resourceId);

                if(_cResourceDraftString != null) {
                    let _cResourceDraftObject = JSON.parse(_cResourceDraftString);
                    
                    if(_cResourceDraftObject[element] != null) {
                        delete _cResourceDraftObject[element];

                        this.persistenceService.setItem('PGDraft_' + resourceId, JSON.stringify(_cResourceDraftObject), 'technical');
                    }
                }

                observer.next(true);
                observer.unsubscribe();
            }, 100)
        })
    }

    postDraftElementData(resourceId:string, element:string, sendData:any) {
        return new Observable<any>((observer) => {
            let _cSendData = JSON.parse(JSON.stringify(sendData));
            _cSendData.id = null;

            this.postResourceData(resourceId, _cSendData).subscribe((data) => {
                this.deleteDraftElement(resourceId, element).subscribe(() => {    
                    observer.next(data);
                    observer.unsubscribe();
                }, () => {    
                    observer.next(data);
                    observer.unsubscribe();
                });
            }, () => {
                observer.error();
                observer.unsubscribe();
            })
        })
    }

    getEnvironmentData() {
        return new Observable<Array<any>>((observer) => {
            this.status.inProgress++;

            this.getRequest('/environment?limit=1000').subscribe(data => {

                this.status.inProgress--;

                if(data == null) data = [];

                observer.next(data as Array<any>);
                observer.unsubscribe();
            }, error => {
                this.status.inProgress--;

                console.debug('DATASERVICE - getEnvironmentData error:', error);

                observer.next([]);
                observer.unsubscribe();
            });
        })
    }

    postEnvironmentData(sendData:any):Observable<any> {
        if(sendData == null) sendData = {};

        return new Observable<string>((observer) => {
            this.status.inProgress++;

            this.postRequest('/environment', sendData).subscribe(data => {
                
                this.status.inProgress--;

                observer.next(data as any);
                observer.unsubscribe();
            }, error => {

                this.status.inProgress--;

                observer.next(null);
                observer.unsubscribe();
            });
        })
    }

    saveEnvironmentData(element:string, sendData:any):Observable<any> {
        return new Observable<any>((observer) => {
            this.status.inProgress++;

            this.putRequest('/environment/' + element, sendData).subscribe(data => {

                this.status.inProgress--;

                observer.next(data);
                observer.unsubscribe();
            }, error => {

                this.status.inProgress--;

                observer.next(null);
                observer.unsubscribe();
            });
        })
    }

    getNotifications(options?:GetResourceDataOptions, relResource?: string, relElement?: string, silent?:boolean):Observable<Array<any>> {
        if(options == null) options = {};

        let _queryURL = '/' + this.getResourceQueryURL('Notification', options, relResource, relElement)

        return new Observable<Array<any>>((observer) => {
            this.status.inProgress++;

            let _reqOptions = null;

            if(silent) {
                _reqOptions = {
                    headers: { 'X-Interceptor-Silent': '*' }
                }
            }

            this.getRequest(_queryURL, _reqOptions).subscribe(data => {

                this.status.inProgress--;

                if(data == null) data = [];

                observer.next(data as Array<any>);
                observer.unsubscribe();
            }, error => {
                this.status.inProgress--;

                console.debug('DATASERVICE - getNotifications error:', error);

                observer.next([]);
                observer.unsubscribe();
            });
        })
    }

    markNotificationsAsRead(list:Array<string>) {
        return new Observable<Array<void>>((observer) => {
            this.status.inProgress++;

            this.postRequest('/notification/read/' + list.join(','), null).subscribe(() => {
                this.status.inProgress--;

                observer.next();
                observer.unsubscribe();
            }, error => {
                this.status.inProgress--;

                console.debug('DATASERVICE - markNotificationsAsRead error:', error);

                observer.next();
                observer.unsubscribe();
            })
        })
    }

    private _mapConnectorResource = {
        'DHM': {
            'Poi': 'destination',
            'Itinerary': 'itinerary',
            'Experience~Event': 'event',
        },
        'TDH': {
            'Poi': 'destination',
            'Itinerary': 'itinerary',
            'Experience~Event': 'event',
            'Article': 'article',
        }
    }

    canPublishOn(connector:string, resource:string) {
        return this.environmentService.isConnectorEnabled(connector) && this._mapConnectorResource[connector] != null && this._mapConnectorResource[connector][resource] != null;
    }

    doPublishOn(connector:string, resource:string, element:string) {
        return new Observable<any>((observer) => {
            if(this.canPublishOn(connector, resource)) {
                this.status.inProgress++;
                let _connectorResource = this._mapConnectorResource[connector][resource];
    
                this.postRequest('/connectors/' + connector.toLowerCase() + '/' + _connectorResource + '/' + element, null).subscribe(() => {
                    this.getElementData(resource, element).subscribe((data) => {
                        this.status.inProgress--;
    
                        observer.next(data);
                        observer.unsubscribe();
                    }, () => {
                        this.status.inProgress--;
    
                        observer.next();
                        observer.unsubscribe();
                    })
                }, error => {
                    this.status.inProgress--;
    
                    console.debug('DATASERVICE - doPublishOn error:', error);
    
                    observer.next();
                    observer.unsubscribe();
                })
            }
            else {
                setTimeout(() => {
                    observer.next();
                    observer.unsubscribe();
                }, 100)
            }
        })
    }

    getSearchTags(search?:string) {
        return new Observable<Array<any>>((observer) => {
            this.status.inProgress++;

            this.getRequest('/data/search/tag?q=' + encodeURIComponent(search)).subscribe((data) => {
                this.status.inProgress--;

                observer.next(data);
                observer.unsubscribe();
            }, error => {
                this.status.inProgress--;

                console.debug('DATASERVICE - getTags error:', error);

                observer.next([]);
                observer.unsubscribe();
            })
        })
    }

    getConnectorExports(connector:string, from:Date, to:Date) {
        return new Observable<Array<any>>((observer) => {
            this.status.inProgress++;

            this.getRequest('/connectors/report?connector=' + connector + '&start_date=' + this._dateToQueryString(from) + '&end_date=' + this._dateToQueryString(to)).subscribe((data) => {
                this.status.inProgress--;

                observer.next(data);
                observer.unsubscribe();
            }, error => {
                this.status.inProgress--;

                console.debug('DATASERVICE - getConnectorExports error:', error);

                observer.next([]);
                observer.unsubscribe();
            })
        })
    }

    private _resolveDirectoryPath(observer:Subscriber<string>, path:Array<string>, create?:boolean, mode?:ResourceMode, parent?:string) {
        if(path.length == 0) {
            observer.next(parent)
            observer.unsubscribe()
        }
        else {
            let _directory = path.shift()
    
            this.getResourceData('Directory', {
                filter: [{
                    field: 'parent_id', operator: '==', value: [parent]
                }, {
                    field: 'label', operator: '==', value: [_directory]
                }]
            }, null, null, mode).subscribe((data) => {
                if(data != null && data[0] != null) {
                    this._resolveDirectoryPath(observer, path, create, mode, data[0].id)
                }
                else {
                    if(!create) {
                        observer.next(null)
                        observer.unsubscribe()
                    }
                    else {
                        this.postResourceData('Directory', { parent_id: parent, name: _directory }, mode).subscribe((data) => {
                            this._resolveDirectoryPath(observer, path, create, mode, data.id)
                        })
                    }
                }
            })
        }
    }

    resolveDirectoryPath(path:Array<string>, create?:boolean, mode?:ResourceMode) {
        return new Observable<string>((observer) => {
            this._resolveDirectoryPath(observer, path, create, mode, null)
        })
    }

    startImporter(importer:string, resource:string) {
        return new Observable<boolean>((observer) => {
            this.postRequest('/importers/' + importer + '/' + this.normalizeResourceName(resource), null).subscribe((data) => {
                observer.next(true);
                observer.unsubscribe();
            }, (error) => {
                observer.next(false);
                observer.unsubscribe();
            })
        })
    }
}