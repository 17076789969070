import { Component, OnInit } from '@angular/core';
import moment from 'moment';
import { PgHelpService } from '../pg-help.service';
import { PgHelpHttpError } from '../../models/pg-help.model';

class PgErrorsElement { 
    time:string
    title:string
    subtitle:string
    body:string
    copied?:boolean
}

@Component({
  selector: 'app-pg-errors',
  templateUrl: './pg-errors.component.html',
  styleUrls: ['./pg-errors.component.scss']
})
export class PgErrorsComponent implements OnInit {

    errors: Array<PgErrorsElement> = [];

    responseFields = ['error','exception','file','line','message']

    showDetails = false;

    constructor(private pgHelpService:PgHelpService) { }

    ngOnInit() {
        this.pgHelpService.httpError.subscribe((data:PgHelpHttpError) => {
            if(data.response.status != 401) {
                let _title = data.response.status + ' - ' + data.response.statusText;
                let _subtitle = data.request.method + ' ' + data.request.url;

                let _error = {
                    time: moment(data.time).format('HH:mm:ss'), 
                    title: _title,
                    subtitle: _subtitle,
                    body: ''
                }
    
                _error.body += 'REQUEST: ' + data.request.method + ' ' + data.request.url + '\n';
    
                if(data.request.body != null) {
                    if(typeof data.request.body != 'object') {
                        _error.body += data.request.body;
                    }
                    else {
                        let _bodyObj = JSON.parse(JSON.stringify(data.request.body))
                        if(_bodyObj.password != null) _bodyObj.password = '*****'
                        _error.body += JSON.stringify(_bodyObj, null, 4);
                    }
                    
                    _error.body += '\n';
                }
    
                _error.body += '\nRESPONSE: ' + data.response.status + ' - ' + data.response.statusText + '\n';
    
                for(let _cField of this.responseFields) {
                    let _cValue = data.response.error[_cField];
    
                    if(_cValue != null) {
                        _error.body += '- ' + _cField.charAt(0).toUpperCase() + _cField.substring(1) + ': ';
    
                        if(typeof _cValue != 'object') {
                            _error.body += _cValue;
                        }
                        else {
                            _error.body += JSON.stringify(_cValue);
                        }
    
                        _error.body += '\n';
                    }
                }
    
                this.errors.push(_error);
            }
        })
    }

    clearErrors() {
        this.errors.splice(0);
    }

    copyError(error:PgErrorsElement) {
        if(!error.copied) {
            let el = document.createElement('textarea');
            el.value = error.body;
            document.body.appendChild(el);
            el.select();
            document.execCommand('copy');
            document.body.removeChild(el);
    
            error.copied = true;
            setTimeout(() => {
                error.copied = false;
            }, 1000)
        }
    }
}
