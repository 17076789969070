import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { PgUserStatusService } from '../../pg-ui-molecules/pg-user-status.service';
import { AuthService } from '../../services/auth.service';
import { DataService } from '../../services/data.service';
import { LocalizationService } from '../../services/localization.service';
import { Websocket2Service, Websocket2Registration } from '../../services/websocket2.service';
import { WSServiceChatChannel, WSServiceChatMessage, WSServiceChatUserWatch } from '../pg-chat.model';
import { CustomerCareBookingData, CustomerCareService } from '../../services/customer-care.service';
import { SoundService } from '../../services/sound.service';
import { EnvironmentService } from '../../services/environment.service';

class WSServiceChatSession {
    id: number
    session_id:string
    username:string
    created_at:string
}

class WSServiceChatBooking {
    id:string;
    from:number;
    to:number;
    guest_name:string;
}

class WSServiceBookingStatus {
    id: string;
    booking: WSServiceChatBooking;
    started: boolean;
    customerCare: {
        user: string,
        online: boolean
    };
    guest: {
        user: string,
        online: boolean
    };
} 

@Component({
  selector: 'app-pg-chat',
  templateUrl: './pg-chat.component.html',
  styleUrls: ['./pg-chat.component.scss']
})
export class PgChatComponent implements OnInit, OnDestroy, AfterViewInit {

    constructor(protected websocketService:Websocket2Service, protected authService:AuthService, protected localizationService:LocalizationService, protected dataService:DataService, protected pgUserStatusService:PgUserStatusService, protected customerCareService:CustomerCareService, protected soundService:SoundService, protected environmentService:EnvironmentService) { }

    @Input() isHidden:boolean;

    @Output() unreadCount = new EventEmitter<number>();
    @Output() notificationCount = new EventEmitter<number>();

    websocketRegistration:Websocket2Registration = null;
    protected _websocketRegistrationSubscriptions:Array<Subscription> = [];

    isLoading = false;

    unathorizedName = '';

    chatBooking:string = null;
    chatBookingData:WSServiceChatBooking = null;

    realmId:string = null;

    customerCareBookingStatus:{ 
        [pid:string]: WSServiceBookingStatus 
    } = {}

    isLoggedIn = false;
    hasAccessError = false;

    isGuest = true;

    customerCareAvailability:number = null;

    channels:{ [id:string]: { 
        status: WSServiceChatChannel,
        unread: number,
        typing: { [user:string]: number }
    } } = {};

    currentChannel:{ [tab:string] : string } = {};

    showChannels = true;

    private _componentSubscriptions:Array<Subscription> = [];
    private _resetTypingInterval = null;

    customerCareBookingsDay:{ [time:string]: CustomerCareBookingData } = null;

    private _checkNewBookingsInterval = null;
    private _warnCustomerCareInterval = null;

    customerCareBlink = false;

    notificationAudio:HTMLAudioElement = null;

    callAudioDevicesOptions:Array<{ value: string, text: string }> = [];
    callVideoDevicesOptions:Array<{ value: string, text: string }> = [];

    callAudioSource:string = null;
    callVideoSource:string = null;

    ngOnInit(): void {
        this.register();

        this._componentSubscriptions.push(this.authService.statusChange.subscribe(() => {
            this.register();
        }))

        let _wasCustomerCare = (this.authService.profile != null && this.authService.profile.customer_care);

        this._componentSubscriptions.push(this.authService.profileChange.subscribe(() => {
            let _isCustomerCare = (this.authService.profile != null && this.authService.profile.customer_care);

            if(_isCustomerCare != _wasCustomerCare) {
                _wasCustomerCare = _isCustomerCare;
                this.register()
            }
        }))

        this._resetTypingInterval = setInterval(() => {
            let _now = Date.now();
            for(let i in this.channels) {
                let _toDelete = [];

                for(let j in this.channels[i].typing) {
                    if(this.channels[i].typing[j] + 2500 < _now) {
                        _toDelete.push(j)
                    }
                }

                for(let _id of _toDelete) {
                    delete this.channels[i].typing[_id]
                }
            }
        }, 1000)

        this.notificationAudio = this.soundService.add('notification')

        // TODO: queste parti della call andrebbero rifattorizzata nel componente della call

        navigator.mediaDevices.enumerateDevices().then((devices) => {
            for(let i = 0; i < devices.length; i ++){
                let _device = devices[i];
                
                if(_device.kind === 'audioinput') {
                    this.callAudioDevicesOptions.push({ value: _device.deviceId, text: _device.label || 'audio ' + (i + 1) })
                }
                else if(_device.kind === 'videoinput') {
                    this.callVideoDevicesOptions.push({ value: _device.deviceId, text: _device.label || 'video ' + (i + 1) })
                }
            };

            if(this.callAudioDevicesOptions.length == 0) {
                this.callAudioDisabled = true;
                this.callAudioSource = null;
            }
            else {
                this.callAudioSource = this.callAudioDevicesOptions[0].value;
            }

            if(this.callVideoDevicesOptions.length == 0) {
                this.callVideoDisabled = true;
                this.callVideoSource = null;
            }
            else {
                this.callVideoSource = this.callVideoDevicesOptions[0].value;
            }
        });
    }

    ngAfterViewInit() {
        this.notificationCount.emit(0);
        
        this._warnCustomerCareInterval = setInterval(() => {
            if(this.customerCareWaitList != null && this.customerCareWaitList.length > 0) {
                if(!this.customerCareBlink) {
                    this.notificationAudio.play();
                    this.customerCareBlink = true;

                    setTimeout(() => {
                        this.customerCareBlink = false;
                    }, 8000)
                }

                this.notificationCount.emit(this.customerCareWaitList.length);
            }
            else {
                this.notificationCount.emit(0);
            }
        }, 1000)
    }

    ngOnDestroy() {
        this._cleanAccess();
        for(let _subscription of this._componentSubscriptions) {
            _subscription.unsubscribe();
        }

        clearInterval(this._resetTypingInterval)
        clearInterval(this._checkNewBookingsInterval)
        clearInterval(this._warnCustomerCareInterval)

        this.soundService.remove(this.notificationAudio)
    }

    private _checkUnreadCount() {
        if(!this.isLoggedIn) {
            this.unreadCount.emit(0)
        }
        else {
            this.unreadCount.emit(this.getUnreadSum())
        }
    }

    register() {
        this._checkUnreadCount()

        if(this.authService.isLoggedIn() && !this._forceGuest) {
            this._handleAccess({ token: true });
        }   
        else if(this.authService.isLoggedIn() || this.canUnauthorizedLogIn() || this.chatBooking != null) {
            this._handleAccess({ name: this.unathorizedName, token: this.authService.isLoggedIn() });
        }
    }

    onUnathorizedNameKeyUp(event:KeyboardEvent) {
        if(event.key == 'Enter') {
            this.unauthorizedLogIn();
        }
    }

    canUnauthorizedLogIn() {
        return this.unathorizedName != null && this.unathorizedName != ''
    }

    unauthorizedLogIn() {
        if(this.canUnauthorizedLogIn()) {
            this._handleAccess({ name: this.unathorizedName })
        }
    }

    private _checkNewBookings() {
        let _now = new Date();

        this.customerCareService.getBookingsDay(this.authService.user.id.toString(), _now.getFullYear(), _now.getMonth() + 1, _now.getDate()).subscribe((data) => {
            if(data == null || Object.keys(data).length == 0) {
                this.customerCareBookingsDay = null;
            }
            else {
                this.customerCareBookingsDay = data;
            }
        })
    }

    protected _cleanAccess() {
        if(this.websocketRegistration != null) {
            this.websocketRegistration.unregister();
        }

        for(let _subscription of this._websocketRegistrationSubscriptions) _subscription.unsubscribe();
        this._websocketRegistrationSubscriptions = [];
    }

    protected _serviceName = 'chat'

    protected _forceGuest = false;

    protected _handleAccess(authData:{ token?:boolean, name?:string }) {
        this._cleanAccess();

        if(authData != null) {
            this.isLoggedIn = true;

            let _data = JSON.parse(JSON.stringify(authData))
            _data.booking = this.chatBooking;
            _data.realm = this.realmId;

            if(this._forceGuest) _data.guest = true;

            if(_data.token) {
                this.websocketRegistration = this.websocketService.register(this._serviceName, async () => {
                    _data.token = await this.authService.getAuthToken();
                    return _data;
                })
            }
            else {
                this.websocketRegistration = this.websocketService.register(this._serviceName, _data)
            }
    
            this.isLoading = true;
            this.hasAccessError = false;
            
            this._websocketRegistrationSubscriptions.push(this.websocketRegistration.statusChange.subscribe(() => {
                if(this.websocketRegistration.status == 'registered') {
                    this.isLoading = false;
                    this.hasAccessError = false;
                    this.isGuest = this.websocketRegistration.profile.guest;

                    if(this.isGuest) {
                        this.customerCareBookingsDay = null;
                        clearInterval(this._checkNewBookingsInterval)
                    }
                    else {
                        this._checkNewBookings()

                        this._checkNewBookingsInterval = setInterval(() => {
                            this._checkNewBookings()
                        }, 60000)
                    }
                }
                else {
                    this.customerCareBookingsDay = null;
                    clearInterval(this._checkNewBookingsInterval)

                    this.channels = {}
                    this.currentChannel = {};
                    this.callToken = null;
                    this.callChannel = null;
                    this.callURL = null;
                    this.isGuest = true;
                    this.customerCareAvailability = null;
                    this.chatBookingData = null;

                    this.userList = [];
                    this.customerCareWaitList = [];
                    this._userDataById = {}
                    
                    if(this.websocketRegistration.status == 'unauthorized') {
                        this.isLoading = false;
                        this.hasAccessError = true;
                    }
                    else {
                        this.isLoading = true;
                        this.hasAccessError = false;
                    }
                }
            }))
    
            this._websocketRegistrationSubscriptions.push(this.websocketRegistration.onMessage.subscribe((data) => {
                if(data != null) {
                    if(data.action == 'typing') {
                        if(data.content) this.channels[data.target].typing[data.from] = Date.now();
                        else {
                            if(this.channels[data.target].typing[data.from] != null) delete this.channels[data.target].typing[data.from]
                        }
                    }
                    else if(data.action == 'message') {
                        this.channels[data.target].status.messages.push(data);
        
                        if(this.getCurrentChannel() != data.target) {
                            this.channels[data.target].unread++;
                        }
                        else {
                            if(this.messagesElement != null && this.messagesElement.nativeElement.scrollTop >= this.messagesElement.nativeElement.scrollHeight - this.messagesElement.nativeElement.offsetHeight) {
                                this._scrollToBottom = true;
                            }
                        }
                    }
                    else if(data.action == 'join_channel' || data.action == 'open_direct') {
                        this.channels[data.target] = { 
                            status: null,
                            typing: {},
                            unread: 0
                        };
    
                        this.setCurrentChannel(data.target)
    
                        this.generateUserList()
                    }
                    else if(data.action == 'leave_channel' || data.action == 'close_direct') {
                        if(this.channels[data.target] != null) {
                            if(this.getCurrentChannel() == data.target) {
                                let _setChannel = null;
    
                                for(let i in this.channels) {
                                    let _beforeCurrent = true;
    
                                    if(this.getChannelTab(i) == this.currentTab) {
                                        if(i == data.target) _beforeCurrent = false;
                                        else _setChannel = i;
    
                                        if(_setChannel != null && !_beforeCurrent) break;
                                    }
                                }
    
                                if(_setChannel == null) {
                                    for(let i in this.channels) {
                                        _setChannel = i;
                                        break;
                                    }
                                }
    
                                this.setCurrentChannel(_setChannel)
                            }
    
                            delete this.channels[data.target];
    
                            this.generateUserList()
                        }
                    }
                    else if(data.action == 'channel_status') {
                        this.channels[data.target].status = data.content;
    
                        this.generateUserList()
                    }
                    else if(data.action == 'channel_status.users') {
                        this.channels[data.target].status.users = data.content;
    
                        this.generateUserList()
                    }
                    else if(/^channel_status\./.test(data.action)) {
                        let _prop = data.action.split('.')[1];
                        this.channels[data.target].status[_prop] = data.content;
                    }
                    else if(data.action == 'user_status') {
                        this._userDataById[data.target] = data.content;
    
                        this.generateUserList()
                    }
                    else if(data.action == 'direct_status') {
                        this.channels[data.target].status = {
                            users: null,
                            call: null,
                            id: data.target,
                            messages: data.content
                        }
                    }
                    else if(data.action == 'join_call') {
                        this.callConfirmed = false;
                        this.callChannel = data.target;
                        this.callToken = data.content.token;
                        this.callURL = data.content.url;
                    }
                    else if(data.action == 'leave_call') {
                        if(this.callChannel == data.target) {
                            this.callConfirmed = false;
                            this.callChannel = null;
                            this.callToken = null;
                            this.callURL = null;
                        }
                    }
                    else if(data.action == 'customercare_availability') {
                        this.customerCareAvailability = data.content;
                    }
                    else if(data.action == 'booking') {
                        this.chatBookingData = data.content;
                    }
                    else if(data.action == 'booking_status') {
                        this.customerCareBookingStatus[data.content.id] = data.content;
                    }

                    this._checkUnreadCount();
                }
            }))
        }
    }

    getChannelTab(channel:string) {
        if(this.isGuestChannel(channel) || this.isCustomerCareChannel(channel)) return 'customer_care'
        else return 'chat'
    }

    setCurrentChannel(channel:string) {
        let _channelTab = this.getChannelTab(channel)

        if(this.currentTab != _channelTab) {
            this.setCurrentTab(_channelTab)
        }

        this.currentChannel[this.currentTab] = channel;

        if(this.channels[channel] != null) {
            this.channels[channel].unread = 0;
        }

        this._scrollToBottom = true;

        this._checkUnreadCount();
    }

    getCurrentChannel() {
        return this.isHidden ? null : this.currentChannel[this.currentTab];
    }

    getCurrentChannelTyping() {
        let _channel = this.getCurrentChannel();
        if(this.channels[_channel] != null) {
            if(Object.keys(this.channels[_channel].typing).length > 0) {
                return this.channels[_channel].typing
            }
        }
    }

    editMessageText:string = ''

    private _lastTyping:{ target: string, time: number } = null;

    onEditMessageTextKeyUp(event:KeyboardEvent) {
        if(this.getCurrentChannel() != null) {
            let _target = this.getCurrentChannel();

            if(event.key == 'Enter' && this.editMessageText != '') {
                this.websocketRegistration.send({
                    action: 'message',
                    target: _target,
                    content: this.editMessageText
                })
    
                this.editMessageText = '';

                this.websocketRegistration.send({
                    action: 'typing',
                    target: _target,
                    content: false
                })
            }
            else {
                let _target = this.getCurrentChannel();
                let _now = Date.now();
                
                if(this._lastTyping == null || this._lastTyping.target != _target || this._lastTyping.time + 1000 < _now) {

                    this._lastTyping = {
                        target: _target,
                        time: _now
                    }

                    this.websocketRegistration.send({
                        action: 'typing',
                        target: _target,
                        content: true
                    })

                }
            }   
        }
    }

    protected _scrollToBottom = false;

    @ViewChild('messagesElement') messagesElement:ElementRef;

    ngAfterViewChecked() {
        if(this._scrollToBottom && this.messagesElement != null) {
            this._scrollToBottom = false;
            this.messagesElement.nativeElement.scrollTop = this.messagesElement.nativeElement.scrollHeight - this.messagesElement.nativeElement.offsetHeight;
        }
    }

    protected _userDataById: { [id:string]: WSServiceChatUserWatch } = {}

    getUserData(id:string) {
        if(typeof this._userDataById[id] == 'undefined') {
            this._userDataById[id] = null;

            this.websocketRegistration.send({
                action: 'watch_user',
                target: id
            })
        }
        
        if(this._userDataById[id] == null) {
            return null;
        }
        else {
            return this._userDataById[id]
        }
    }

    getUserName(id:string) {
        let _userData = this.getUserData(id);
        if(_userData == null || _userData.profile == null) return id;
        else {
            let _retVal = '';
            let _propList = ['name','surname'];

            for(let i of _propList) {
                if(_userData.profile[i] != null) {
                    if(_retVal != '') _retVal += ' ';
                    _retVal += _userData.profile[i]
                }
            }

            if(_retVal == '') return id;
            else return _retVal.replace(/\s+/g, ' ').replace(/([^\s])[^\s]+\s/g, '$1. ');
        }
    }

    getUserStatusClass(id:string) {
        let _userData = this.getUserData(id);
        if(_userData != null) {
            if(_userData.status == 'online') return 'success'
            else if(_userData.status == 'busy') return 'danger'
        }
        
        return 'secondary'
    }

    getSessionStatusClass(session:any) {
        if(session.closed_at != null) return 'secondary';
        else return 'success'
    }

    leaveCurrentChannelOrDirect() {
        let _channel = this.getCurrentChannel();

        if(/^#/.test(_channel) || /^\?/.test(_channel)) {
            this.websocketRegistration.send({
                action: 'leave_channel',
                target: _channel
            })
        }
        else if(/^@/.test(_channel)) {
            this.websocketRegistration.send({
                action: 'close_direct',
                target: _channel
            })
        }
    }

    getMessageTime(message:WSServiceChatMessage) {
        return this.localizationService.format.time(message.time);
    }

    hasMessageDate(messages:Array<WSServiceChatMessage>, index) {
        if(index == 0) return true;
        else {
            return this.getMessageDate(messages[index]) != this.getMessageDate(messages[index - 1])
        }
    }

    hasMessageUserAndTime(messages:Array<WSServiceChatMessage>, index) {
        if(index == 0) return true;
        else if(messages[index].from != messages[index - 1].from) return true;
        else {
            return this.getMessageTime(messages[index]) != this.getMessageTime(messages[index - 1])
        }
    }

    getMessageDate(message:WSServiceChatMessage) {
        return this.localizationService.format.date(message.time);
    }

    getUnreadSum() {
        let _sum = 0;

        for(let i in this.channels) {
            _sum += this.channels[i].unread
        }

        return _sum;
    }

    getUserGuestChannel(user:string) {
        return user.replace(/^guest_/, '?');
    }
    
    openDirectOrGuestChannel(user:string) {
        if(/^guest_/.test(user)) {
            let _channel = this.getUserGuestChannel(user);

            if(this.channels[_channel] != null) {
                this.setCurrentChannel(_channel)
            }
            else {
                this.websocketRegistration.send({
                    action: 'join_channel',
                    target: _channel
                })
            }
        }
        else {
            let _channel = '@' + user;

            if(this.channels[_channel] != null) {
                this.setCurrentChannel(_channel)
            }
            else {
                this.websocketRegistration.send({
                    action: 'open_direct',
                    target: _channel
                })
            }
        }
    }

    getChannelName(id:string) {
        if(/^@/.test(id)) {
            return this.getUserName(id.replace(/^@/, ''))
        }
        else if(/^\?/.test(id)) {
            return this.getUserName(id.replace(/^\?/, 'guest_'))
        }
        else if(this.isCustomerCareChannel(id)) {
            if(/^#customer-care-/.test(id)) {
                return '#customer-care ' + this.environmentService.getRealmName(id.split('-')[1])
            }
            else {
                return id;
            }
        }
        else return id;
    }

    getChannelStatusClass(id:string) {
        if(/^@/.test(id)) {
            return this.getUserStatusClass(id.replace(/^@/, ''))
        }
        else if(/^\?/.test(id)) {
            return this.getUserStatusClass(id.replace(/^\?/, 'guest_'))
        }
        else return null;
    }

    callToken:string = null;
    callChannel:string = null;
    callURL:string = null;

    callCompressed = false;
    callConfirmed = false;

    callAudioDisabled = false;
    callVideoDisabled = false;

    joinCallCurrentChannel() {
        let _channel = this.getCurrentChannel();

        if(this.callChannel != _channel) {
            this.leaveCurrentCall();
            
            this.websocketRegistration.send({
                action: 'join_call',
                target: _channel
            })
        }
    }

    getCallParticipantsCount(channel:WSServiceChatChannel) {
        if(channel != null && channel.call != null) {
            return Object.keys(channel.call).length;
        }
    }

    getCallParticipantsList(channel:WSServiceChatChannel) {
        let _retVal = '';

        if(channel != null && channel.call != null) {
            for(let i in channel.call) {
                if(_retVal != '') _retVal += ', '
                return this.getUserName(i)
            }
        }

        return _retVal;
    }

    leaveCurrentCall() {
        if(this.callChannel != null) {
            this.websocketRegistration.send({
                action: 'leave_call',
                target: this.callChannel
            })
        }
    }

    isGuestChannel(channel:string) {
        return /^\?/.test(channel);
    }

    isLoadingGuestSessions = false;

    onGuestSessionsOpenChange(open:boolean) {
        if(open) {
            this.loadGuestSessions();
        }
    }

    guestSessions:Array<any> = []

    loadGuestSessions() {
        this.isLoadingGuestSessions = true;
        this.guestSessions = [];

        this.dataService.getResourceData('ChatSession', { filter: [
            { field: 'closed_at', operator: '==', value: null }
        ] }).subscribe((data) => {
            this.isLoadingGuestSessions = false;
            this.guestSessions = data;

            this.dataService.getResourceData('ChatSession', { filter: [
                { field: 'closed_at', operator: '!=', value: null },
                { field: 'message_count', operator: '>', value: [ 0 ] },
            ] }).subscribe((data) => {
                for(let _item of data) {
                    let _isAlreadyIn = false;

                    for(let _session of this.guestSessions) {
                        if(_session.id == _item.id) {
                            _isAlreadyIn = true;
                            break;
                        }
                    }

                    if(!_isAlreadyIn) {
                        this.guestSessions.push(_item)
                    }
                }
            }, () => {
                this.isLoadingGuestSessions = false;
            })
        }, () => {
            this.isLoadingGuestSessions = false;
        })
    }

    getSessionDateTime(session:WSServiceChatSession) {
        return this.localizationService.format.datetime(session.created_at)
    }

    joinGuestSession(session:WSServiceChatSession) {
        let _channel = '?' + session.session_id;

        if(this.channels[_channel] != null) {
            this.setCurrentChannel(_channel)
        }
        else {
            this.websocketRegistration.send({
                action: 'join_channel',
                target: _channel
            })
        }
    }
    
    isCustomerCareEnabled() {
        return this.channels['#customer-care'] != null;
    }

    isCustomerCareChannel(channel:string) {
        return /^#customer-care/.test(channel)
    }

    userList:Array<string> = [];
    customerCareWaitList:Array<string> = [];

    generateUserList() {
        this.customerCareWaitList = [];
        this.userList = [];
        
        for(let i in this.channels) {
            if(this.channels[i].status != null) {
                for(let _user of this.channels[i].status.users) {
                    let _userData = this.getUserData(_user);

                    if(_userData != null) {
                        if(_userData.profile.guest) {
                            if(_userData.status == 'online' && _userData.profile.id != this.websocketRegistration.profile.id) {
                                if(this.userList.indexOf(_user) == -1) this.customerCareWaitList.push(_user);
                            }
                        }
                        else {
                            if(_userData.status != 'offline' && (this.authService.user == null || _userData.id != this.authService.user.id)) {
                                if(this.userList.indexOf(_user) == -1) this.userList.push(_user)
                            }
                        }
                    }
                }
            }
        }

        this.userList.sort((a, b) => {
            let _aVal = this.getUserName(a);
            let _bVal = this.getUserName(b);

            if(_aVal < _bVal) return -1;
            else if(_aVal > _bVal) return 1;
            else return 0;
        })
    }

    currentTab:string = 'chat';

    setCurrentTab(tab:string) {
        this.currentTab = tab;
        this._scrollToBottom = true;
    }

    @ViewChild('joinChannelMenu') joinChannelMenu:NgbDropdown

    joinChannelId:string = '';

    onJoinChannelIdKeyUp(event:KeyboardEvent) {
        if(event.key == 'Enter') {
            this.joinChannel()
        }
    }

    joinChannel() {
        if(this.joinChannelId != '' && this.joinChannelId != '#') {
            let _channel = '#' + this.joinChannelId.replace(/^#*/, '');

            if(this.channels[_channel] != null) {
                this.setCurrentChannel(_channel)
            }
            else {
                this.websocketRegistration.send({
                    action: 'join_channel',
                    target: _channel
                })    
            }
    
            this.joinChannelId = '';

            this.joinChannelMenu.close();
        }
    }

    onChatWithUserClick(user:string) {
        this.openDirectOrGuestChannel(user);

        this.joinChannelMenu.close();
    }

    isSaving = false;

    setFiles(files:Array<File>) {
        if(!this.isSaving && files != null && files.length > 0) {
            this.isSaving = true;

            let _channel = this.getCurrentChannel();
            let _message = this.editMessageText;

            this.editMessageText = '';
            
            this.dataService.postFile('File', files[0]).subscribe((data) => {
                if(data.complete) {
                    this.isSaving = false;

                    if(data.data != null) {
                        this.websocketRegistration.send({
                            action: 'message',
                            target: _channel,
                            content: _message,
                            data: {
                                type: 'attachment',
                                name: data.data.nome_file,
                                url: data.data.url
                            }
                        })
                    }
                }
            })
        }
    }
}
