import {
    Component,
    forwardRef,
    Input,
    ViewChild,
    ElementRef,
    OnChanges,
    OnDestroy
} from '@angular/core';

import {
    Location
} from '@angular/common';

import { 
    ControlValueAccessor, 
    NG_VALUE_ACCESSOR, 
    Validator, 
    NG_VALIDATORS 
} from '@angular/forms';

import { Editor } from 'tinymce';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { LocalizationService } from '../../services/localization.service';
import { PgHtmlMode } from '../../models/config.resources.model';


@Component({
    selector: 'app-pg-html-editor',
    templateUrl: './pg-html-editor.component.html',
    styleUrls: ['./pg-html-editor.component.scss'],
    providers: [{
        provide: NG_VALUE_ACCESSOR, 
        useExisting: forwardRef(() => PgHtmlEditorComponent),
        multi: true
    }, {
        provide: NG_VALIDATORS,
        useExisting: forwardRef(() => PgHtmlEditorComponent),
        multi: true,
    }]
})

export class PgHtmlEditorComponent implements ControlValueAccessor, Validator, OnChanges, OnDestroy {

    constructor(private location:Location, private modalService:NgbModal, private localizationService:LocalizationService) {

    }

    @Input() fieldId:string;

    @Input() readonly: boolean;
    @Input() required: boolean;

    @Input() allowAdvanced:boolean;

    @Input() htmlMode:PgHtmlMode;

    showAdvanced = false;

    editor:Editor;
    value:string = null;

    allowedPasteTags = ['b','i','u','strong','em']

    editorToolbarsByHtmlMode = {
        'inline-only': 'cut copy fixPaste | bold italic underline | link | undo redo',
        'full': 'cut copy fixPaste | bold italic underline | h3 | link | entouranceImage | undo redo',
    }

    editorParams = { 
        base_url: this.location.prepareExternalUrl('/assets/tinymce'), 
        suffix: '.min',
        plugins: ['link'],
        menubar: false,
        statusbar: false,
        toolbar: null,
        paste_preprocess: (editor, args) => {
            args.content = (args.content as string).replace(/<[^>]*>/g, (match) => {
                let _tag = match.replace(/^</, '').replace(/>$/, '').replace(/\s.*$/, '').toLowerCase();

                let _hasSlash = _tag.charAt(0) == '/'
                if(_hasSlash) _tag = _tag.substring(1)

                if(this.allowedPasteTags.indexOf(_tag) == -1) {
                    return '';
                }
                else {
                    return '<' + (_hasSlash ? '/' : '') + _tag + '>'
                }
            })
        },
        entity_encoding: 'raw',
        setup: (editor:Editor) => {
            editor.ui.registry.addButton('entouranceImage', {
                icon: 'image',
                tooltip: this.localizationService.translate('pg-html-editor.insert-image'),
                onAction: (_) =>  {  
                    this.selectImageOpen();
                }
            });

            editor.ui.registry.addButton('fixPaste', {
                icon: 'paste',
                tooltip: 'Paste',
                onAction: (_) =>  {  
                    navigator.clipboard.readText().then((text) => {
                        if(text != null && text != '') {
                            this.editor.insertContent(text);
                        }
                    })
                }
            });

            editor.on('init', () => {
                this.editor = editor;

                this.editor.readonly = this.readonly;

                this.setEditorValue();
            });
            
            editor.on('change', () => {
                this.value = editor.getContent();
                if(this._onTouched != null) this._onTouched();
                if(this._onChange != null) this._onChange(this.value);
            });

            editor['___remove'] = editor.remove
            editor.remove = () => {
                try {
                    editor['___remove']()
                }
                catch(ex) {
                    console.error(ex)
                }
            }
        }
    }

    ngOnChanges() {
        if(this.htmlMode == null) this.htmlMode = 'full'

        this.editorParams.toolbar = this.editorToolbarsByHtmlMode[this.htmlMode]

        if(this.editor != null) {
            this.editor.readonly = this.readonly;
        }
    }

    ngOnDestroy(): void {
    }

    setEditorValue() {
        if(this.editor != null) {
            this.editor.undoManager.clear()
            this.editor.setContent(this.value == null ? '' : this.value, { format: 'raw' });
        }
    }

    @ViewChild('modalSelectImage') modalSelectImage:ElementRef;

    selectImageValue:string = null;

    private _selectImageModalRef:NgbModalRef = null;

    selectImageOpen() {
        this.selectImageValue = null;
        this._selectImageModalRef = this.modalService.open(this.modalSelectImage);
        this._selectImageModalRef.result.then(() => {
            if(this.selectImageValue != null && this.selectImageValue != '') {
                this.editor.insertContent('<figure><img style="object-fit: cover; height: 100%; width: 100%;" src="' + this.selectImageValue + '"><figcaption>' + this.localizationService.translate('pg-html-editor.image-caption') + '</figcaption></figure>')
            }
        }, () => {})
    }

    selectImageDismiss() {
        this._selectImageModalRef.dismiss();
    }

    selectImageClose() {
        this._selectImageModalRef.close();
    }

    // INTERFACCIA ControlValueAccessor

    writeValue(obj: any) {
        this.value = obj;

        this.setEditorValue();
    }

    _onChange;

    registerOnChange(fn: any) {
        this._onChange = fn;
    }

    _onTouched;

    registerOnTouched(fn: any) {
        this._onTouched = fn;
    }

    // INTERFACCIA Validator

    validate() {
        return (!this.required || (this.value != '' && this.value != null)) ? null : {
            required: {
                valid: false
            }
        }
    };
}
  