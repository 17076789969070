import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { EnvironmentService } from './environment.service';
import { PersistenceService } from './persistence.service';

@Injectable({
  providedIn: 'root'
})
export class WebPushService {

    constructor(private environmentService:EnvironmentService, private authService:AuthService, private persistenceService:PersistenceService) { }

    private _registration:ServiceWorkerRegistration = null;

    enabled = false;

    async init() {
        this._registration = await navigator.serviceWorker.getRegistration();

        let _fileName = 'web-push-sw.js?v=' + this.environmentService.getBuildTime();

        if(this._registration == null) {
            console.log('WebPushService init: service worker missing')

            await navigator.serviceWorker.register(_fileName)

            this._registration = await navigator.serviceWorker.ready;
        }
        else {
            if(!this._registration.active.scriptURL.endsWith(_fileName)) {
                console.log('WebPushService init: service worker changed version')

                this._deleteSubscription();

                await(new Promise<void>((resolve) => {
                    setTimeout(() => {
                        resolve();
                    }, 250)
                }))

                await this._registration.unregister()

                await navigator.serviceWorker.register(_fileName)

                this._registration = await navigator.serviceWorker.ready;
            }
        }

        this.enabled = this.isSupported() && window.Notification.permission == 'granted' && !this.persistenceService.getItem('WebPushDisable');

        this.authService.afterLogin.subscribe(() => {
            this._checkSubscription();
        })

        this.authService.beforeLogout.subscribe(() => {
            //this._checkSubscription();
            // NB: qua se uso la _checkSubscription non funziona perché non ho ancora effettivamente fatto logout
            this._deleteSubscription();
        })

        await this._checkSubscription();

        console.log('WebPushService init: finished')
    }

    isSupported() {
        return window.Notification != null
    }

    async enable() {
        if(this.isSupported()) {
            const permission = await window.Notification.requestPermission()
            if (permission == 'granted') {
                this.enabled = true;
                this.persistenceService.removeItem('WebPushDisable')
                await this._checkSubscription();
            }
        }
    }

    async disable() {
        this.enabled = false;
        this.persistenceService.setItem('WebPushDisable', 'true', 'technical')
        await this._checkSubscription();
    }

    private async _checkSubscription() {
        if(this.enabled) {
            if(this.authService.isLoggedIn()) {
                await this._createSubscription();
            }
            else {
                await this._deleteSubscription();
            }
        }
        else {
            await this._deleteSubscription();
        }
    }

    private async _createSubscription() {
        await this._sendMessage({ 
            APIUrl: this.environmentService.environment.APIUrl,
            operation: 'create',
            token: await this.authService.getAuthToken()
        })
    }

    private async _deleteSubscription() {
        await this._sendMessage({ 
            APIUrl: this.environmentService.environment.APIUrl,
            operation: 'delete',
            token: await this.authService.getAuthToken()
        })
    }
    
    private _sendMessage(data:any) {
        this._registration.active.postMessage(JSON.stringify(data))
    }

}
