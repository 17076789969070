import { Component } from '@angular/core';
import { FormFlow, PgFormField, PgFormGroup, PgFormLayout } from '../../../models/form.model';
import { ConfigFormLayoutGroupField } from '../../../models/config.resources.model';
import { StepFormBase } from '../step-form-base';

@Component({
  selector: 'app-forms-eatery',
  templateUrl: './forms-eatery.component.html',
  styleUrls: ['./forms-eatery.component.scss']
})
export class FormsEateryComponent extends StepFormBase {

    formId = 'eatery';
    resourceId = 'Eatery';

    createFormFlow() {
        let _phoneWarnMessage = this.localizationService.translate('forms-' + this.formId + '.warning.phone-sms');

        let _phoneWarnIf = '($value != null && /^\\+/.test($value) && !/^\\+[39|33|34|44|49|1]/.test($value) ? "' + _phoneWarnMessage + '" : null)'

        return new FormFlow({
            'dati_generali': {
                title: null,
                form: new PgFormLayout([
                    new PgFormField({ label: 'auto', type: 'boolean', name: 'is_broker', default: false, tooltip: 'auto' }),
                    new PgFormField({ label: 'auto', type: 'string', name: 'broker_code', tooltip: 'auto' }),
                    new PgFormField({ label: 'auto', type: 'string', name: 'name', required: true, tooltip: 'auto' }),
                    new PgFormField({ label: 'auto', type: 'string', name: 'company_name', required: true, tooltip: 'auto' }),
                    new PgFormField({ label: 'auto', type: 'phone', name: 'landline_number' }),
                    new PgFormField({ label: 'auto', type: 'phone', name: 'phone', required: true, tooltip: (this.environmentService.environment.DisableSMS ? null : 'auto'), warnIf: _phoneWarnIf }),
                    new PgFormField({ label: 'auto', type: 'email', name: 'email', required: true }),
                    new PgFormField({ label: 'auto', type: 'string', name: 'website' }),
                    new PgFormField({ label: 'auto', type: 'string', name: 'store_url' }),
                    new PgFormField({ label: 'auto', type: 'select', multi: true, name: 'languages', required: true }),
                    new PgFormField({ label: 'auto', type: 'location', name: 'geolocation', placeholder: 'auto', 
                        slaves: {
                            'country': 'country',
                            'region': 'region',
                            'province': 'province',
                            'city': 'city',
                            'address': 'address',
                            'zipcode': 'zipcode',
                            'timezone': 'timezone'
                        }
                    }),
                    new PgFormField({ label: 'auto', type: 'info', name: 'geolocation_missing', tooltip: 'auto', infoType: 'warning' }),
                    new PgFormField({ label: 'auto', type: 'string', name: 'location_description@EateryTranslation', locale: true }),
                    new PgFormField({ label: 'auto', type: 'string', name: 'country', readonly: true }),
                    new PgFormField({ label: 'auto', type: 'string', name: 'region', readonly: true }),
                    new PgFormField({ label: 'auto', type: 'string', name: 'province', readonly: true }),
                    new PgFormField({ label: 'auto', type: 'string', name: 'city', readonly: true }),
                    new PgFormField({ label: 'auto', type: 'string', name: 'address', readonly: true }),
                    new PgFormField({ label: 'auto', type: 'string', name: 'zipcode', readonly: true }),
                    new PgFormField({ label: 'auto', type: 'string', name: 'timezone', readonly: true, default: 'Europe/Rome' }),
                    new PgFormField({ label: 'auto', type: 'select', name: 'group_id' }),
                    new PgFormField({ label: 'auto', type: 'select', name: 'realm_id' }),
                    new PgFormField({ label: 'auto', type: 'select', name: 'priority', default: 5, required: true }),
                ])
            },
            'descrizione': {
                title: null,
                form: new PgFormLayout([
                    new PgFormField({ label: 'auto', type: 'boolean', name: 'bookable', default: true }),
                    new PgFormField({ label: 'auto', type: 'string', name: 'booking', tooltip: 'auto' }),
                    new PgFormField({ label: 'auto', type: 'select', name: 'type', required: true }),
                    new PgFormField({ label: 'auto', type: 'select', name: 'cuisine_type', multi: true }),
                    new PgFormField({ label: 'auto', type: 'rating', name: 'cost_range', icon: 'fas fa-euro-sign' }),
                ])
            },
            'cucina': {
                title: null,
                form: new PgFormLayout([
                    new PgFormField({ label: 'auto', type: 'html', name: 'description@EateryTranslation', required: true, locale: true, maxLength: 350 }),
                    new PgFormField({ label: 'auto', type: 'html', name: 'special_dishes@EateryTranslation', locale: true }),
                    new PgFormField({ label: '', type: 'timetable-openings', name: 'availability', multi: true })
                ])
            },
            'servizi': {
                title: null,
                form: new PgFormLayout([
                    new PgFormField({ label: 'auto', type: 'select', multi: true, name: 'general_services' })
                ])
            },
            'menu': {
                title: null,
                description: 'auto',
                multi: 'menu',
                form: new PgFormLayout([
                    new PgFormField({ label: 'auto', type: 'string', name: 'menu_name', required: true, locale: true }),
                    new PgFormField({ label: 'auto', type: 'html', name: 'menu_description', locale: true }),
                    new PgFormField({ label: 'auto', type: 'decimal', name: 'menu_price' }),
                    new PgFormField({ label: 'auto', type: 'string', name: 'menu_price_unit', default: '€', tooltip: 'auto' }),
                ])
            },
            'file': {
                title: null,
                form: new PgFormLayout([
                    new PgFormField({ label: 'auto', type: 'file', name: 'menu_file' }),
                    new PgFormField({ label: 'auto', type: 'file', name: 'menu_file_2' }),
                    new PgFormField({ label: 'auto', type: 'file', multi: true, fileType: 'videos', name: 'videos' }),
                    new PgFormField({ label: 'auto', type: 'file', multi: true, fileType: 'images', name: 'images_outside' }),
                    new PgFormField({ label: 'auto', type: 'file', multi: true, fileType: 'images', name: 'images_bar' }),
                    new PgFormField({ label: 'auto', type: 'file', multi: true, fileType: 'images', name: 'images_hall' }),
                    new PgFormField({ label: 'auto', type: 'file', multi: true, fileType: 'images', name: 'images_dishes' })
                ])
            },
            'survey': {
                title: null,
                description: 'auto',
                form: new PgFormLayout([
                    new PgFormField({ label: '', type: 'survey', name: 'survey', locale: true }),
                ])
            }
        })
    }

    customizeFormFlow() {
        for(let _cField of ['country','region','province','city','address','zipcode','timezone']) {
            this.formFlow.states['dati_generali'].form.getFieldByName(_cField).visible = false;
        }

        this.formFlow.states['dati_generali'].form.getFieldLayout('geolocation_missing').condition = '!$form.poi_id && !$form.geolocation';
        
        this.formFlow.states['dati_generali'].form.getFieldByName('priority').visible = false;

        this.formFlow.states['descrizione'].form.getFieldLayout('bookable').display = { fullWidth: false }
        this.formFlow.states['descrizione'].form.getFieldLayout('booking').condition = '$form.bookable';

        let _fieldsList:Array<ConfigFormLayoutGroupField> = []

        while(this.formFlow.states['descrizione'].form.formGroups[0].fields.length > 2) {
            _fieldsList.unshift(this.formFlow.states['descrizione'].form.formGroups[0].fields.pop())
        }

        this.formFlow.states['descrizione'].form.formGroups.push(new PgFormGroup({
            collapsible: false,
            fields: _fieldsList
        }))
    }

    afterInit() {
        if(this.environmentService.environment.CheckoutURL != null) {
            let _id = this.elementId;
            if(this.dataService.isDraftId(_id)) _id = 'id';

            this.formFlow.states['descrizione'].form.getFieldByName('booking').placeholder = this.environmentService.environment.CheckoutURL + '/book-eatery/' + _id;
        }
        else {
            this.formFlow.states['descrizione'].form.getFieldByName('booking').placeholder = null;
        }
    }

    mapImportData(data:google.maps.places.PlaceResult) {
        let _addressComponents = this.parseAddressComponents(data.address_components)

        return {
            country: _addressComponents.country,
            region: _addressComponents.region,
            province: _addressComponents.province,
            city: _addressComponents.city,
            address: _addressComponents.address,
            zipcode: _addressComponents.zipcode,
            website: data.website,
            landline_number: data.international_phone_number,
            name: data.name,
            geolocation: { 
                coordinates: [data.geometry.location.lat(), data.geometry.location.lng()], 
                type: 'Point' 
            }
        }
    }

    adaptLoadData(values:any) {
        this._lastTimezone = null;

        return super.adaptLoadData(values);
    }

    adaptSaveData(values:any) {
        if(values.booking == '') values.booking = null;

        return values;
    }

    onValuesChange(data) {
        super.onValuesChange(data);

        this._checkTimezone(data);
    }

    private _lastTimezone = null;

    _checkTimezone(data) {
        if(data.timezone != this._lastTimezone) {
            this._lastTimezone = data.timezone;

            this.formFlow.states.disponibilita.form.getFieldByName('availability').timezone = data.timezone;
        }
    }
}
