import { Component, Input, Output, EventEmitter } from '@angular/core';
import { PgChatComponent } from '../pg-chat/pg-chat.component';

@Component({
  selector: 'app-pg-customer-care',
  templateUrl: './pg-customer-care.component.html',
  styleUrls: ['./pg-customer-care.component.scss']
})
export class PgCustomerCareComponent extends PgChatComponent {
    @Input() unathorizedName:string;
    @Input() chatBooking:string;
    @Input() realmId:string;

    @Input() hasCustomerCareBooking:boolean

    @Output() customerCareBookingRequest = new EventEmitter<void>()

    waitTimedOut = false;

    ngOnInit(): void {
        this._forceGuest = true;

        let _timeout = 60000;

        if(this.environmentService.environment.CustomerCareTimeout != null) {
            _timeout = this.environmentService.environment.CustomerCareTimeout * 1000;
        }

        setTimeout(() => {
            this.waitTimedOut = true;
        }, _timeout)

        super.ngOnInit()
    }

    isWaiting() {
        if(!this.isLoggedIn || this.isLoading || this.channels[this.getCurrentChannel()] == null) return true;
        else {
            if(this.callChannel != null) return false;
            else if(this.channels[this.getCurrentChannel()].status != null && this.channels[this.getCurrentChannel()].status.messages.length > 0) return false;

            return true;
        }
    }
}
